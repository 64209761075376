/**
 * external libs
 */
import React, {useState} from 'react'
import {useHistory} from "react-router-dom";
/**
 * styles
 */
import styles from "../Item/index.module.scss";
/**
 * services
 */
import MedicalCardService from "../../../../services/medicalCard.service";
/**
 * constants
 */
import {MEDICAL_CARDS_MAKE_PAGE_URI} from "../../../../constants/uri.constant";
import {useContext} from "react";
import {AlertContext} from "../../../../pages/context/alert.context";
import CatalogService from "../../../../services/catalog.service";
import AddNewCardMemberModal from "../../../Modal/AddNewCardMember";
import FolderIdEnum from "../../../../enums/api/FolderId";
import IncarnationEnum from "../../../../enums/api/Incarnation";
import matchTheSameMembers from "../../../../utils/matchTheSameMembers";
import {Tooltip} from "antd";
import {useTranslation} from "react-i18next";

export default function AddMedCard({folderId, folder}) {
    const {setAlertError} = useContext(AlertContext)
    const history = useHistory()
    const [isReady, setIsReady] = useState(true);
    const [openSelectMember, setOpenSelectMember] = useState(false);
    const {t} = useTranslation()

    const createMedCardFlow = async () => {
        setIsReady(false)

        try {
            const {rows, include} = await CatalogService.content({
                folder_id: folderId,
                catalog_id: folder.catalog.id,
                depth: 1,
            })




            if(!rows.length){
                const currentGlobalMembers = matchTheSameMembers(include.global_members)
                const currentLocalMembers = matchTheSameMembers(include.local_members)
                const allMembers = [...currentGlobalMembers, ...currentLocalMembers]
                const allFiltredMembers = [...new Set(allMembers.map(({id}) => id))].map(currentId => {
                    return allMembers.find(({id}) => id === currentId)
                })

                return await createNewMedCard({
                    member: allFiltredMembers.map(({id, dimension, columnOptions}) => ({
                        id: id,
                        incarnation: columnOptions?.dimension.incarnation[0].id || dimension.incarnation[0].id
                    }))
                })
            }

            setOpenSelectMember(true)
        }
        catch (error){
            setAlertError(error?.message)
        }
        finally {
            setIsReady(true)
        }
    }

    const createNewMedCard = async (params = {}) => {
        try {
            const emptyMember = {
                [IncarnationEnum.id.cardHolder]: [],
                [IncarnationEnum.id.card]: [],
            }

            setOpenSelectMember(false)

            return history.push({
                pathname: MEDICAL_CARDS_MAKE_PAGE_URI(folderId,"new", FolderIdEnum.contacts),
                state: {
                    parentFolderId: folderId,
                    member: (params.member || []).reduce((members, member) => {
                        return {
                            ...members,
                            [member.incarnation]: [
                                ...(members[member.incarnation] || []),
                                member.id
                            ]
                        }
                    }, emptyMember),
                },
            })
        }
        catch (error){
            setAlertError(error?.message)
        }
    }

    if(openSelectMember){
        return <AddNewCardMemberModal catalogId={folder.catalog.id}
                                      onOk={(member) => createNewMedCard({member})}
                                      onClose={() => setOpenSelectMember(false)}
                                      folderId={folderId}/>
    }


    if (!isReady) {
        return (
            <svg className={`${styles.item__action}`} xmlns="http://www.w3.org/2000/svg" version="1.0" width="150px"
                 height="150px" viewBox="0 0 128 128">
                <path fill="#323238"
                      d="M64.4 16a49 49 0 0 0-50 48 51 51 0 0 0 50 52.2 53 53 0 0 0 54-52c-.7-48-45-55.7-45-55.7s45.3 3.8 49 55.6c.8 32-24.8 59.5-58 60.2-33 .8-61.4-25.7-62-60C1.3 29.8 28.8.6 64.3 0c0 0 8.5 0 8.7 8.4 0 8-8.6 7.6-8.6 7.6z">
                    <animateTransform attributeName="transform" type="rotate" from="0 64 64" to="360 64 64" dur="1800ms"
                                      repeatCount="indefinite"></animateTransform>
                </path>
            </svg>
        )
    }

    return (
        <Tooltip placement="top" title={t("Create card")}>
            <svg className={`${styles.item__action} ${styles.item__action_add}`} onClick={createMedCardFlow} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M12 19.7C16.2526 19.7 19.7 16.2526 19.7 12C19.7 7.74741 16.2526 4.3 12 4.3C7.74741 4.3 4.3 7.74741 4.3 12C4.3 16.2526 7.74741 19.7 12 19.7ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="#1084FF"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M12 8.35C12.359 8.35 12.65 8.64102 12.65 9V11.35H15C15.359 11.35 15.65 11.641 15.65 12C15.65 12.359 15.359 12.65 15 12.65H12.65V15C12.65 15.359 12.359 15.65 12 15.65C11.641 15.65 11.35 15.359 11.35 15V12.65H9C8.64102 12.65 8.35 12.359 8.35 12C8.35 11.641 8.64102 11.35 9 11.35H11.35V9C11.35 8.64102 11.641 8.35 12 8.35Z" fill="#1084FF"/>
            </svg>
        </Tooltip>
    )
}
