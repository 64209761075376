/**
 * external libs
 */
import React, {useEffect, useMemo, useRef, useState} from 'react'
import {Input} from "antd";
/**
 * styles
 */
import styles from './index.module.scss'
/**
 * enums
 */
import ResultKeysEnum from "../../../../../../enums/api/ResultKeys";
import FieldItem from "../../../../../Form/components/FieldItem";
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import ReactQuill from 'react-quill';
import {EditorState, convertToRaw, ContentState, SelectionState, convertFromRaw} from 'draft-js';
import 'react-quill/dist/quill.snow.css';
import {draftToMarkdown, markdownToDraft} from 'markdown-draft-js';
import {useTranslation} from "react-i18next";

const BtnCustom = ({setCustomEditMode}) => {
    return (
        <div className={styles.customBtn} onClick={() => setCustomEditMode(editMode => !editMode)}>
            Source
        </div>
    )
}

export default function EditableHTMLWidget({
                                                   isFocused,
                                                   widget,
                                                   resultValue,
                                                   onEdit,
                                                   validationErrorMessage,
                                                   field,
                                                   onBlur,
                                                   result
                                               }) {
    const editorRef = useRef(null)
    const [resultData, setResultData] = useState(result)
    const [openToolbar, setOpenToolbar] = useState(false)
    const [openCustomEditMode, setCustomEditMode] = useState(false)
    const [editorState, setEditorState] = useState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(resultValue || "").contentBlocks)))

    const onChangeHandler = (editorState) => {
        setEditorState(editorState)
        const value = draftToHtml(convertToRaw(editorState.getCurrentContent())) || null;

        const newMeasuresValue = field ? {
            ...resultData.measures,
            [field]: value,
        } : value

        setResultData({
            ...resultData,
            is_modified: true,
            measures: newMeasuresValue
        })
        onEdit({
            ...resultData,
            is_modified: true,
            measures: newMeasuresValue
        })
    }

    const onChangeTextarea = (e) => {
        const value = e.target.value;

        const newMeasuresValue = field ? {
            ...resultData.measures,
            [field]: value,
        } : value

        setResultData({
            ...resultData,
            is_modified: true,
            measures: newMeasuresValue
        })
        onEdit({
            ...resultData,
            is_modified: true,
            measures: newMeasuresValue
        })
    }


    const focusHandler = () => {
        const contentState = editorState.getCurrentContent();
        const selection = new SelectionState({
            anchorKey: contentState.getFirstBlock().getKey(),
            anchorOffset: 0,
            focusKey: contentState.getLastBlock().getKey(),
            focusOffset: contentState.getLastBlock().getLength(),
        });
        setEditorState(EditorState.forceSelection(editorState, selection));
    }


    useEffect(() => {
        if(isFocused){
            focusHandler()
        }
    }, [isFocused])

    return (
        <FieldItem label={widget.description || ""}
                   requiredColor={resultValue ? "#1084FF" : "red"}
                   required={!widget.type.includes("null")} stylesWrapper={{marginBottom: 10}} error={validationErrorMessage}>
            <div className={styles.wrapper} style={{maxWidth: 500, width: 500}}>
                <div className={styles.wrapper__toolbarBtn} onClick={() => setOpenToolbar(!openToolbar)}>
                    {openToolbar ? (
                        <img src="/image/icon/toolbarDown.svg" alt=""/>
                    ) : (
                        <img src="/image/icon/toolbarUp.svg" alt=""/>
                    )}
                </div>
                <Editor
                    toolbarHidden={!openToolbar}
                    editorState={editorState}
                    defaultEditorState={editorState}
                    wrapperClassName="demo-wrapper"
                    onFocus={focusHandler}
                    editorClassName={`demo-editor ${openCustomEditMode && "closeEditor"}`}
                    toolbarClassName={`demo-toolbar ${openCustomEditMode && "hideToolbarItems"}`}
                    onEditorStateChange={onChangeHandler}
                    toolbarCustomButtons={ [<BtnCustom setCustomEditMode={setCustomEditMode}/>]}
                />
                {openCustomEditMode && (
                    <textarea className={styles.wrapper__textarea} name="code" id="" cols="30" rows="10" value={resultValue} onChange={onChangeTextarea}/>
                )}
            </div>
        </FieldItem>
    )


    // return (
    //     <FieldItem label={widget.description || ""} stylesWrapper={{marginBottom: 10}} error={validationErrorMessage}>
    //         <div className={styles.wrapper}>
    //             <ReactQuill theme="snow" defaultValue={resultValue || ""} onChange={onChangeHandler} />
    //         </div>
    //     </FieldItem>
    // )
}
