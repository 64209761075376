/**
 * Клас для спрощення роботи з датами
 */
import detectLanguage from "./detectLanguage";

const getDateFormatPattern = (locale) => {
    const getPatternForPart = (part) => {
        switch (part.type) {
            case 'day':
                return 'd'.repeat(part.value.length);
            case 'month':
                return 'm'.repeat(part.value.length);
            case 'year':
                return 'y'.repeat(part.value.length);
            case 'literal':
                return part.value;
            default:
                console.log('Unsupported date part', part);
                return '';
        }
    };

    return new Intl.DateTimeFormat().formatToParts(new Date('2021-01-01'))
        .map(getPatternForPart)
        .join('')
        .toUpperCase();
};
const currentDateFormat = getDateFormatPattern(detectLanguage())


export default class DateTime {
    static getCurrentDateFormat() {
        return currentDateFormat
    }
    /**
     * Перетворює дату на формат D-m-Y
     */
    static dateToBackendFormat(dateObj) {
        return dateObj.toISOString()

        // "2023-11-10T12:27:43"
    }

    /**
     * Перетворює дату на формат D-m-Y
     */
    static dateFromBackend(dateObj, data) {
        let newDataObj = dateObj;
        const lastSymbol = dateObj.split("")[dateObj.split("").length - 1]

        if(lastSymbol !== "Z"){
            newDataObj += "Z"
        }

        return newDataObj
    }

    /**
     * Перетворює дату на формат D-m-Y
     */
    static toLocaleString(dateObj, locale = "") {
        return dateObj.toLocaleString()
    }

    /**
     * Перетворює дату на формат D-m-Y
     */
    static toLocaleDate(dateObj, locale = "") {
        return dateObj.toLocaleDateString()
    }

    /**
     * Перетворює дату на формат D-m-Y
     */
    static toLocaleTime(dateObj, locale = "") {
        const date = dateObj.toLocaleTimeString();
        const [time, prefix] = date.split(" ")
        const [hours, minutes] = time.split(":");
        return `${hours}:${minutes}${prefix ? ` ${prefix}` : ""}`
    }

    /**
     * Перетворює дату на формат Y-m-d
     */
    static dateToYmd(dateObj, split = "-") {
        const month = (dateObj.getMonth() + 1) <= 9 ? '0' + (dateObj.getMonth() + 1) : dateObj.getMonth() + 1;
        const date = dateObj.getDate() <= 9 ? '0' + dateObj.getDate() : dateObj.getDate();

        return [dateObj.getFullYear(), month, date].join(split);
    }

    /**
     * Преобразовывает время в формат H:i:s
     */
    static timeToHis(dateObj) {
        const timeHi = DateTime.timeToHi(dateObj);
        const seconds = dateObj.getSeconds() <= 9 ? '0' + dateObj.getSeconds() : dateObj.getSeconds();

        return [timeHi, seconds].join(':');
    }

    /**
     * Перетворює час на формат H:i:s
     */
    static timeToHi(dateObj) {
        const hours = dateObj.getHours() <= 9 ? '0' + dateObj.getHours() : dateObj.getHours();
        const minutes = dateObj.getMinutes() <= 9 ? '0' + dateObj.getMinutes() : dateObj.getMinutes();

        return [hours, minutes].join(':');
    }

    /**
     * Перетворює дату на формат Y-m-d H:i
     */
    static dateToDmyHi(dateObj, separator = "-", separatorBetweenTime = "") {
        return DateTime.toLocaleDate(dateObj) + ` ${separatorBetweenTime} ` + DateTime.toLocaleTime(dateObj);
    }

    static addYears(date, years = 1) {
        date.setFullYear(date.getFullYear() + years);

        return date;
    }

    static addMoth(date, month = 1) {
        date.setMonth(date.getMonth() + month);

        return date
    }

    static addWeek(date, weeks = 1) {
        date.setDate(date.getDate() + 7 * weeks);

        return date;
    }
}
