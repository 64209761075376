/**
 * external libs
 */
import React, {useMemo} from 'react'
/**
 * enums
 */
import WidgetImage from "../WidgetImage";
/**
 * enums
 */
import ResultKeysEnum from "../../../../enums/api/ResultKeys";
import getWidget from "../../../../utils/getWidget";
import WidgetFile from "../WidgetFile";

const isImageWidget = (extensions) => {
    if (!extensions) {
        return false;
    }

    return ["png", "jpg", "svg", "webp" , "jpeg"].some(ext => extensions.includes(ext))
}

const isFileWidget = (extensions) => {
    if (!extensions) {
        return false;
    }

    return ["svs", "pdf", "doc", "docx"].some(ext => extensions.includes(ext))
}

const isVideoWidget = (extensions) => {
    if (!extensions) {
        return false;
    }
    return ["mp4", "wav", "avi"].some(ext => extensions.includes(ext))
}

export default function WidgetURL({isFocused, result, widget, field, showDescription, ...props}) {
    const extension = useMemo(() => {
        const url = result.measures?.[field]?.url

        if (!url) {
            return widget.result.schema.properties[field]['x-path'].extension
        }

        return [url.split('.')[url.split('.').length - 1]]
    }, [result])

    // if (isImageWidget(extension)) {
    //     return <WidgetImage widget={widget} result={result} extensions={extension}  showDescription={showDescription} field={field} {...props}/>
    // }
    //
    // if (isFileWidget(extension)) {
    //     return <WidgetFile widget={widget} extensions={extension} result={result} showDescription={showDescription} field={field} {...props}/>
    // }
    //
    // if (isVideoWidget(extension)) {
    //     return <WidgetImage result={result} showDescription={showDescription} field={field} {...props}/>
    // }

    return <WidgetFile widget={widget} extensions={extension} result={result} showDescription={showDescription} field={field} {...props}/>
}
