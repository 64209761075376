/**
 * external libs
 */
import React, {useEffect, useMemo, useState} from 'react'
import ColumnsMember from "../ColumnsMember";

export default function LineMember({
                                       memberData = [],
                                       addMembersData,
                                       hideUnusedRowsMode,
                                       removeMembersData,
                                       setOpenSelectMember,
                                       setSelectedMembers,
                                       selectedMembers,
                                       getIsFinish,
                                       getCatalog,
                                       rowPosition,
                                       columnsCount,
                                   }) {
    const addNewMembersRows = async (index, isLeaf = false, params = {}) => {
        let currentMembersRow = memberData.filter((_, columnIndex) => columnIndex <= index)
        let matchCurrentMemberRow = selectedMembers.reduce( (matchCurrentMemberRow, currentMember) => {
            if(currentMembersRow.some( member => member.id === currentMember.id)){
                return matchCurrentMemberRow
            }


            return [...matchCurrentMemberRow, currentMember]
        }, currentMembersRow)

        const membersParams = matchCurrentMemberRow.filter(({columnOptions}) => !columnOptions.is_multiple).map(({id}) => id);

        if (isLeaf) {
            setSelectedMembers(matchCurrentMemberRow)
            setOpenSelectMember(false)
            getIsFinish(true)
            return true
        }

        const {rows} = await getCatalog({
            ...params,
            members: membersParams
        })

        if(!rows.length && params.free_text){
            return null
        }

        if (!rows.length || isLeaf) {
            setSelectedMembers(matchCurrentMemberRow)
            setOpenSelectMember(false)
            getIsFinish(true)
            return true
        }

        //Продолжаем
        setSelectedMembers(matchCurrentMemberRow)
        await addMembersData(rows.map(row => [...currentMembersRow, ...row]), rowPosition, membersParams)
    }

    const checkedLine = async ( index, checkedColumn ) => {
        let currentMembersRow = memberData.filter((_, columnIndex) => columnIndex < index)
        let matchCurrentMemberRow = selectedMembers.reduce( (matchCurrentMemberRow, currentMember) => {
            if(currentMembersRow.some( member => member.id === currentMember.id)){
                return matchCurrentMemberRow
            }


            return [...matchCurrentMemberRow, currentMember]
        }, currentMembersRow)

        if(matchCurrentMemberRow.some( member => member?.id === checkedColumn?.id)){
            setSelectedMembers(matchCurrentMemberRow.filter( member => member.id !== checkedColumn.id))
            return;
        }

        setSelectedMembers([...matchCurrentMemberRow, checkedColumn])
    }

    const removeNewMembersRows = async (index, isLeaf) => {
        if(hideUnusedRowsMode){
            let currentMembersRow = memberData.filter((_, columnIndex) => columnIndex <= (index - 1))
            const membersParams = currentMembersRow.map(({id}) => id);


            const {rows} = await getCatalog({
                members: membersParams
            })

            if(!rows.length){
                return null
            }

            setSelectedMembers(currentMembersRow)
            await addMembersData(rows.map(row => [...currentMembersRow, ...row]), rowPosition, membersParams)
            return ;
        }

        let currentMembersRow = memberData.filter((_, columnIndex) => columnIndex <= index)
        const membersParams = currentMembersRow.map(({id}) => id);

        removeMembersData(currentMembersRow, rowPosition, membersParams)
    }

    return (
        <tr>
            {new Array(columnsCount).fill("").map((_, columnIndex) => {
                const membersRowColumn = memberData[columnIndex];
                const lastColumns = columnIndex + 1 === memberData.length;
                const openColumns = !lastColumns && memberData.length > 1

                return (
                    <ColumnsMember columnsCount={columnsCount}
                                   key={columnIndex}
                                   openColumns={openColumns}
                                   columnIndex={columnIndex}
                                   lastColumn={false}
                                   selectedMembers={selectedMembers}
                                   removeNewMembersRows={removeNewMembersRows}
                                   addNewMembersRows={addNewMembersRows}
                                   column={membersRowColumn}
                                   checkedLine={checkedLine}
                    />
                )
            })}
        </tr>
    )
}
