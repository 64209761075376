/**
 * external libs
 */
import React, {createContext, useCallback, useState} from 'react';
import {notification} from 'antd';
import { InfoCircleOutlined, CheckCircleOutlined, WarningOutlined, CloseCircleOutlined } from '@ant-design/icons';

export const NavigationRefreshContext = createContext({});

export function NavigationRefreshContextProvider({children}) {
    const [generalNavigationRefresh, setGeneralNavigationRefresh] = useState(null);

    return (
        <NavigationRefreshContext.Provider value={{
            generalNavigationRefresh,
            setGeneralNavigationRefresh,
        }}>
            {children}
        </NavigationRefreshContext.Provider>
    );
}
