/**
 * internal constants
 */
import {
    ACCESS_TOKEN_KEY,
    ACTIVE_AUTH_METHOD,
    LANG_CODE_KEY,
    NAVIGATION_STATE_KEY, REDIRECT_URL,
    REFRESH_TOKEN_KEY
} from '../constants/storage.constant';

export default class StorageService {
    static get accessToken() {
        return localStorage.getItem(ACCESS_TOKEN_KEY);
    }

    static set accessToken(token) {
        return localStorage.setItem(ACCESS_TOKEN_KEY, token);
    }

    static removeAccessToken() {
        return localStorage.removeItem(ACCESS_TOKEN_KEY);
    }

    static get navigationState() {
        return localStorage.getItem(NAVIGATION_STATE_KEY);
    }

    static set navigationState(token) {
        return localStorage.setItem(NAVIGATION_STATE_KEY, token);
    }

    static removeNavigationState() {
        return localStorage.removeItem(NAVIGATION_STATE_KEY);
    }

    static get refreshToken() {
        return localStorage.getItem(REFRESH_TOKEN_KEY);
    }

    static set refreshToken(token) {
        return localStorage.setItem(REFRESH_TOKEN_KEY, token);
    }

    static removeRefreshToken() {
        return localStorage.removeItem(REFRESH_TOKEN_KEY);
    }

    static get langCode() {
        return localStorage.getItem(LANG_CODE_KEY);
    }

    static set langCode(langCode) {
        return localStorage.setItem(LANG_CODE_KEY, langCode);
    }

    static removeLangCode() {
        return localStorage.removeItem(LANG_CODE_KEY);
    }

    static get activeAuthMethod() {
        return localStorage.getItem(ACTIVE_AUTH_METHOD);
    }

    static set activeAuthMethod(token) {
        return localStorage.setItem(ACTIVE_AUTH_METHOD, token);
    }

    static removeActiveAuthMethod() {
        return localStorage.removeItem(ACTIVE_AUTH_METHOD);
    }

    static get redirectUrl() {
        return localStorage.getItem(REDIRECT_URL);
    }

    static set redirectUrl(url) {
        return localStorage.setItem(REDIRECT_URL, url);
    }

    static removeRedirectUrl() {
        return localStorage.removeItem(REDIRECT_URL);
    }
}
