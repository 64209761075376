/**
 * external libs
 */
import React, {lazy, useEffect, useMemo, useRef, useState} from 'react'
import {DatePicker, ConfigProvider} from 'antd';
import 'dayjs/locale/ru';
import dayjs from 'dayjs';
import ReactDOM from 'react-dom';
import frFR from 'antd/locale/ru_RU';
/**
 * styles
 */
import styles from './index.module.scss'
import FieldItem from "../../../../../Form/components/FieldItem";
import {DatePickerForm} from "../../../../../Form/Items/DatePicker";
import DateTime from "../../../../../../utils/DateTime";

const MyDatePanel = () => {
    return (
        <div>
            <input type="text"/>
        </div>
    )
}

export default function EditableDateTimeWidget({onEdit, resultValue, field, widget, isFocused, result}) {
    const [isOpen, setIsOpen] = useState(!!isFocused);
    const datePickerRef = useRef(null)
    const [resultData, setResultData] = useState(result)

    const defaultValue = useMemo(() => {
        if (!widget.default) {
            return null
        }

        if (widget.default === "now") {
            return new Date()
        }

        return new Date(DateTime.dateFromBackend(widget.default))

    }, [resultValue])

    const onChangeHandler = (date) => {
        const newMeasuresValue = field ? {
            ...resultData.measures,
            [field]: (date ? DateTime.dateToBackendFormat(new Date(date)) : null),
        } : (date ? DateTime.dateToBackendFormat(new Date(date)) : null)


        const newResultData = {
            ...resultData,
            is_modified: true,
            measures: newMeasuresValue
        }

        setResultData(newResultData)
        onEdit(newResultData)
    }

    // const ggg = async () => {
    //     const b = await import(`antd/locale/ru_RU`);
    //     setLaaa(b.default)
    //     console.log(b, "bbbbbbbb")
    // }

    useEffect(() => {
        setResultData(result)
    }, [result])

    useEffect(() => {
        if (isFocused && datePickerRef.current) {
            setTimeout(() => {
                datePickerRef.current.querySelector('input').select();
            }, 50)
        }
    }, [isFocused])

    useEffect(() => {
        if (defaultValue) {
            onChangeHandler(defaultValue)
        }
    }, [])


    return (
        <FieldItem label={widget.description || ""} required={!widget.type.includes("null")}
                   requiredColor={resultValue ? "#1084FF" : "red"} stylesWrapper={{marginBottom: 10}}>
            <div className={styles.wrapper} style={{maxWidth: 500, width: 500}} ref={datePickerRef}>
                <DatePicker
                    defaultValue={resultValue ? dayjs(new Date(resultValue)) : defaultValue ? dayjs(new Date(defaultValue)) : null}
                    onFocus={() => setIsOpen(true)}
                    autoFocus={isOpen}
                    open={isOpen}
                    onOpenChange={setIsOpen}
                    className={styles.wrapper__date}
                    style={{width: "100%"}}
                    format={[`${DateTime.getCurrentDateFormat()} HH:mm`]}
                    showTime={{
                        format: "HH:mm"
                    }}
                    onChange={onChangeHandler}/>
            </div>
        </FieldItem>
    )
}
