/**
 * external libs
 */
import React, {useEffect, useRef, useMemo, useState} from 'react'
import {DatePicker} from 'antd';
import dayjs from 'dayjs';
/**
 * styles
 */
import styles from './index.module.scss'
import FieldItem from "../../../../../Form/components/FieldItem";
import {useTranslation} from "react-i18next";
import DateTime from "../../../../../../utils/DateTime";


export default function EditableDateWidget({onEdit, field, widget, resultValue, isFocused, result}) {
    const [isOpen, setIsOpen] = useState(!!isFocused);
    const {t} = useTranslation()
    const datePickerRef = useRef(null)
    const [resultData, setResultData] = useState(result)

    const defaultValue = useMemo(() => {
        if(!widget.default){
            return null
        }

        if(widget.default === "now"){
            return new Date()
        }

        return new Date(widget.default)

    }, [resultValue])

    const onChangeHandler = (date, str) => {
        const newMeasuresValue = field ? {
            ...resultData.measures,
            [field]: (date ? DateTime.dateToYmd(new Date(date), "-") : null),
        } : (date ? DateTime.dateToYmd(new Date(date), "-") : null)

        const newResultData = {
            ...resultData,
            is_modified: true,
            measures: newMeasuresValue
        }

        setResultData(newResultData)
        onEdit(newResultData)
    }


    useEffect(() => {
        setResultData(result)
    }, [result])

    useEffect(() => {
        if (isFocused && datePickerRef.current) {
            setTimeout(() => {
                // datePickerRef.current.querySelector('input').select();
            }, 50)
        }
    }, [isFocused])


    useEffect(() => {
        if(defaultValue){
            onChangeHandler(defaultValue)
        }
    }, [])

    return (
        <FieldItem label={widget.description || ""} requiredColor={resultValue ? "#1084FF" : "red"}  required={!widget.type.includes("null")} stylesWrapper={{marginBottom: 10}}>
            <div className={styles.wrapper} style={{maxWidth: 500, width: 500}} ref={datePickerRef}>
                <DatePicker
                    defaultValue={resultValue ? dayjs(new Date(resultValue)) : defaultValue ? dayjs(new Date(defaultValue)) : null}
                    onFocus={() => setIsOpen(true)}
                    autoFocus={isOpen}
                    open={isOpen}
                    onOpenChange={setIsOpen}
                    className={styles.wrapper__date}
                    style={{width: "100%"}}
                    format={[DateTime.getCurrentDateFormat()]}
                    showToday={false}
                    placeholder={t("Select date")}
                    onChange={onChangeHandler}/>
            </div>
        </FieldItem>
    )
}
