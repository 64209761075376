import AuthService from "../../../services/auth.service";
import {EventType, PublicClientApplication} from "@azure/msal-browser";
import {msalConfig} from "../../../authConfig/azure";
import {MsalProvider} from "@azure/msal-react";
import React from "react";
import AuthModuleService from "../../../services/authModule/authModule.service";

const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback((event) => {
    // set active account after redirect
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        if (account) {
            msalInstance.setActiveAccount(account);
        }
    }
}, error => {
    console.log(error, "Msal in redirect callback event error");
});


export default function AzureAuth({ children }){
    AuthModuleService.instance = msalInstance;

    return (
        <MsalProvider instance={msalInstance}>
            {children}
        </MsalProvider>
    )
}
