/**
 * external libs
 */
import React, {createContext, useCallback, useEffect, useState} from 'react';
import {notification} from 'antd';
import { InfoCircleOutlined, CheckCircleOutlined, WarningOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { ConfigProvider as  AntdConfigProvider } from 'antd';
import detectLanguage from "../../utils/detectLanguage";
import fr from "antd/locale/uk_UA"
import AcceptedLanguageEnum from "../../enums/AcceptedLanguage";
export const AntdConfigContext = createContext({});

export function AntdConfigContextProvider({children}) {
    const [local, setLocal] = useState(null);

    const getLocal = async () => {
        let module = null;
        const lang = detectLanguage()
        if(lang === AcceptedLanguageEnum.en){
            module = await import(`antd/locale/en_US`);
        }
        if(lang === AcceptedLanguageEnum.uk){
            module = await import(`antd/locale/uk_UA`);
        }
        if(lang === AcceptedLanguageEnum.ru){
            module = await import(`antd/locale/ru_RU`);
        }
        if(lang === AcceptedLanguageEnum.zh){
            module = await import(`antd/locale/zh_CN`);
        }
        if(lang === AcceptedLanguageEnum.pl){
            module = await import(`antd/locale/pl_PL`);
        }
        console.log(module, "modulemodulemodulemodule")
        setLocal(module?.default || null)
    }

    useEffect(() => {
        getLocal()
    }, [])

    return (
        <AntdConfigContext.Provider value={{}}>
            <AntdConfigProvider locale={local}>
                {children}
            </AntdConfigProvider>
        </AntdConfigContext.Provider>
    );
}
