/**
 * external libs
 */
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import MetaTags from 'react-meta-tags';
/**
 * components
 */
/**
 * styles
 */
import './general.scss';
import './font.css';
import 'react-phone-input-2/lib/style.css'
/**
 * helpers
 */
import './helpers/axios';
/**
 * localers
 */
import enTranslate from "./locales/en";
import ukTranslate from "./locales/uk";
import plTranslate from "./locales/pl";
import zhTranslate from "./locales/zh";
import ruTranslate from "./locales/ru";
import detectLanguage from "./utils/detectLanguage";
import ErrorBoundary from "./components/ErrorBoundary";
import Web from "./pages/web";
/**
 * context
 */
import {AlertContextProvider} from "./pages/context/alert.context";
import {TranslateContextProvider} from "./pages/context/translate.context";
import FaviconIcon from '../src/assets/favicon.ico'

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: enTranslate()
            },
            uk: {
                translation: ukTranslate()
            },
            pl: {
                translation: plTranslate()
            },
            ru: {
                translation: ruTranslate()
            },
            zh: {
                translation: zhTranslate()
            },
        },
        lng: detectLanguage(),
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        }
    });


ReactDOM.render(
    <React.StrictMode>
        <MetaTags>
            <link rel="icon" href={process.env.REACT_APP_FAVICON_LINK || FaviconIcon} />
            <meta name="description" content="This is the best app"/>
        </MetaTags>
        <ErrorBoundary>
            <BrowserRouter>
                <TranslateContextProvider>
                    <AlertContextProvider>
                        <Web/>
                    </AlertContextProvider>
                </TranslateContextProvider>
            </BrowserRouter>
        </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root'),
)




