import React from 'react'
import {AuthContextProvider} from "../context/auth.context";
import {WidgetContextProvider} from "../context/widget.context";
import {ConfigProvider as ConfigCountryPhoneInput} from "antd-country-phone-input";
import en from 'world_countries_lists/data/countries/en/world.json';
import Routes from "../../components/Routes";
import {GlobalFolderProvider} from "../context/globalFolder.context";
import AuthModules from "../../components/Auth";
import StorageService from "../../services/storage.service";
import {AntdConfigContextProvider} from "../context/antdConfig.context";

StorageService.redirectUrl = window.location.pathname + window.location.search

export default function Web() {
    return (
        <AuthModules>
            <GlobalFolderProvider>
                <AuthContextProvider>
                    <WidgetContextProvider>
                        <ConfigCountryPhoneInput locale={en}>
                            <AntdConfigContextProvider>
                                <Routes/>
                            </AntdConfigContextProvider>
                        </ConfigCountryPhoneInput>
                    </WidgetContextProvider>
                </AuthContextProvider>
            </GlobalFolderProvider>
        </AuthModules>
    )
}
