/**
 * external libs
 */
import React, {useEffect, useRef, useState} from 'react'
import {Input} from "antd";
/**
 * styles
 */
import styles from './index.module.scss'
/**
 * enums
 */
import ResultKeysEnum from "../../../../../../enums/api/ResultKeys";
import FieldItem from "../../../../../Form/components/FieldItem";

const mapLatter = {
    81: "Q",
    87: "W",
    69: "E",
    82: "R",
    84: "T",
    89: "Y",
    85: "U",
    73: "I",
    79: "O",
    80: "P",
    65: "A",
    83: "S",
    68: "D",
    70: "F",
    71: "G",
    72: "H",
    74: "J",
    75: "K",
    76: "L",
    90: "Z",
    88: "X",
    67: "C",
    86: "V",
    66: "B",
    78: "N",
    77: "M",
}


export default function EditableStringWidget({ isFocused, widget, resultValue, onEdit, validationErrorMessage, field, onBlur, result}){
    const properties = widget["x-properties"];
    const [value, setValue] = useState(resultValue || "")
    const [resultData, setResultData] = useState(result)
    const inputRef = useRef(null)
    const changeLastLaterToCharCode = useRef(false)
    const ctrlDowned = useRef(false)
    const InputPassword = widget?.format === "password" ? Input.Password : Input


    const onBlurHandler = () => {
        if(onEdit){
            onEdit(resultData)
        }

        if(onBlur){
            onBlur(resultData)
        }
    }

    const onChangeHandler = (e) => {
        onBlur()
        let value = e.target.value || null;

        if(!ctrlDowned.current && changeLastLaterToCharCode.current && value){
            value = value.slice(0, -1)

            if(properties.case === "upper"){
                value = `${value}${mapLatter[changeLastLaterToCharCode.current].toUpperCase()}`;
            }
            if(properties.case === "lower"){
                value = `${value}${mapLatter[changeLastLaterToCharCode.current].toLowerCase()}`;
            }
        }

        const newMeasuresValue = field ? {
            ...resultData.measures,
            [field]: value,
        } : value

        if(value?.length > widget.maxLength){
            onBlur({
                ...resultData,
                is_modified: true,
                measures: newMeasuresValue
            })
        }

        setValue(value)
        setResultData({
            ...resultData,
            is_modified: true,
            measures: newMeasuresValue
        })
        onEdit({
            ...resultData,
            is_modified: true,
            measures: newMeasuresValue
        })
        changeLastLaterToCharCode.current = false
    }

    const onKeyDown = (e) => {
        const charCtrl = 17;
        const chatCommandLeft = 91;
        const chatCommandRight = 93;
        if([charCtrl, chatCommandLeft, chatCommandRight].includes(e.which)){
            ctrlDowned.current = true
            return;
        }

        if(properties?.layout && !!mapLatter[e.which]){
            // return changeLastLaterToCharCode.current = !!mapLatter[e.which]
            return changeLastLaterToCharCode.current = e.which
        }

        changeLastLaterToCharCode.current = null
    }

    const onKeyUp = (e) => {
        const charCtrl = 17;
        const chatCommandLeft = 91;
        const chatCommandRight = 93;
        if([charCtrl, chatCommandLeft, chatCommandRight].includes(e.which)){
            ctrlDowned.current = false
        }
    }

    useEffect(() => {
        setResultData(result)
    }, [result])

    useEffect(() => {
        if(isFocused){
            inputRef.current.select()
        }
    }, [])



    return (
        <>
            <FieldItem label={widget.description || ""}
                       requiredColor={value ? "#1084FF" : "red"}
                       required={!widget.type.includes("null")} stylesWrapper={{marginBottom: 10, position: "relative", zIndex: 10}} error={validationErrorMessage}>
                <div className={styles.wrapper} style={{maxWidth: 500, width: 500}}>
                    <InputPassword rootClassName={styles.wrapper__input}
                                    ref={inputRef}
                                    value={value}
                                    onKeyDown={onKeyDown}
                                    onKeyUp={onKeyUp}
                                    suffix={widget?.['x-unit']}
                                    onChange={onChangeHandler}
                                    onBlur={onBlurHandler}
                    />
                </div>
            </FieldItem>
        </>
    )
}
