/**
 * external libs
 */
import React, {useEffect, useMemo, useRef, useState} from 'react'
/**
 * components
 */
import EditableImageWidget from "./components/Editable";
import File from "./components/File";
import ResultModel from "../../../../model/medicalCard/result.model";

export default function WidgetFile({result, tooltipShow = true, field, widget, extensions, editable, showDescription, onEdit, canBeLink = true, onLoad = () => {}}) {
    const imageLoadedRef = useRef([])
    const [isAllImageLoaded, setIsAllImageLoaded] = useState(false);
    const files = useMemo(() => {
        if(Array.isArray(result?.measures?.[field])){
            return result?.measures?.[field]
        }

        return result?.measures?.[field] ? [result?.measures?.[field]] : [];
    }, [result?.measures?.[field], result?.measures?.[field]?.length])

    const imageLoad = (index) => {
        imageLoadedRef.current[index] = true

        if(imageLoadedRef.current.length === files.length && imageLoadedRef.current.every( imageLoaded => imageLoaded)){
            onLoad(true)
        }
    }

    const autoGenerateUrl = () => {
        if(!widget){
            return null
        }

        const currentWidget  = widget.result.schema.properties[field];

        if(!currentWidget || result?.measures?.[field]?.url){
            return null
        }

        const {upload_url, auto_generate, extension} = currentWidget['x-path'];

        if(!auto_generate){
            return null
        }

        let currentUrl = upload_url;
        const [ext] = extension;
        currentUrl = currentUrl.replace("{result.id}", result.id.toUpperCase())
        currentUrl = currentUrl.replace("{token}", result.token || new ResultModel().token)
        currentUrl = currentUrl.replace("{ext}", ext)
        currentUrl = currentUrl.replace("{azure_blob_storage}", process.env.REACT_APP_AZURE_BLOB_STORAGE)

        onEdit({
            ...result,
            is_modified: true,
            measures: {
                ...result.measures,
                [field]: {
                    url: currentUrl,
                },
            }
        })
    }

    useEffect(() => {
        autoGenerateUrl()
    }, [])

    const generateFileLink = (fileUrl) => {
        if (fileUrl.endsWith('.cor-ekg')) {
            return `/viewer/ekg?path=${fileUrl}`;
        }
        return fileUrl;
    };

    if (editable && widget && !widget.result.schema.properties[field].auto_generate) {
        return <EditableImageWidget widget={widget.result.schema.properties[field]}
                                    originalWidget={widget}
                                    result={result}
                                    field={field}
                                    onEdit={onEdit}
                                    files={files}
                                    canBeLink={canBeLink}
                                    showDescription={false}
                                    extensions={extensions}
                                    onBlur={() => {
                                    }}/>
    }

    return (
        <div style={{display: 'flex', alignItems: 'center', gap: 15}}>
            {files.map( (file, index) => (
                <File key={file.url} extensions={extensions} tooltipShow={tooltipShow} imageLoad={() => imageLoad(index)} field={field} file={{...file, url: generateFileLink(file.url)}} canBeLink={canBeLink} result={result} showDescription={showDescription} widget={widget}/>
            ))}
        </div>
    )
}
