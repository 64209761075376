import IncarnationModel from "./incarnation.model";
import CatalogFolderModel from "../catalogFolder.model";
import ParseMedicalCardApi from "../../utils/api/ParseMedicalCardApi";
import RecordModel from "./record.model";
import ResultModel from "./result.model";
import WarningModel from "./warnings.model";

export default class FolderModel {
    constructor(data = {}) {
        this.id = data.id;
        this.name = data.name;
        this.icon = data.icon;
        this.included_members = data.included_members || [];
        this.excluded_members = data.excluded_members || [];
        this.permitted_members = data.permitted_members || [];
        this.ordinal = data.ordinal;
        this.parent = data.parent;
        this.catalog = data.catalog ? new CatalogFolderModel(data.catalog) : null;
        this.incarnation = data.incarnation ? new IncarnationModel(data.incarnation) : null;
        this.record = data.record ? data.record.map( record => new RecordModel({
            ...record,
            medCardMembers: data.medCardMembers
        })) : [];
        this.warning = new WarningModel(data.warning);
    }
}
