/**
 * external libs
 */
import React, {useContext, useEffect, useMemo, useState} from 'react'
import {useTranslation} from "react-i18next";
/**
 * components
 */
import SideBar from '../SideBar'
import SideBarTop from './components/Top'
/**
 * styles
 */
import styles from './index.module.scss'
/**
 * utils
 */
import ParseMedicalCardApi from "../../utils/api/ParseMedicalCardApi";
/**
 * constants
 */
import {
    MEDICAL_CARDS_LIST_MAKE_PAGE_URI,
    MEDICAL_CARDS_MAKE_PAGE_URI,
    SETTINGS_CONTENT_PAGE_URI,
    SETTINGS_FODLERS_PAGE_URI,
    SETTINGS_DOCUMENTATION_USER_AGREEMENT_PAGE_URI,
    SETTINGS_DOCUMENTATION_PERSONAL_INFO_PAGE_URI,
    NOTIFICATIONS_PAGE_URI,
    SETTINGS_WIDGET_PAGE_URI,
    SETTINGS_SECURITY_PAGE_URI
} from "../../constants/uri.constant";
import generateIconUrl from "../../utils/generateIconUrl";
/**
 * enums
 */
import IncarnationIdEnum from "../../enums/api/Incarnation";
/**
 * context
 */
import {AuthContext} from "../../pages/context/auth.context";
import MedicalCardService from "../../services/medicalCard.service";
import FolderIdEnum from "../../enums/api/FolderId";
import {useHistory} from "react-router-dom";
import checkPermission from "../../utils/checkPermission";
import RolesEnums from "../../enums/api/Roles";
import {GlobalFolderContext} from "../../pages/context/globalFolder.context";


const formatFolderToSideBarItems = ( folder, onClick )  => {
    return {
        iconProps: {
            backendUrl: generateIconUrl(folder.icon || "folder", "icon")
        },
        addCard: true,
        folderId: folder.id,
        link: MEDICAL_CARDS_LIST_MAKE_PAGE_URI(folder.id),
        title: folder.name,
        onClick: onClick,
        hash: folder.name.toLowerCase().replaceAll(" ", "-"),
        folder: folder
    }
}

export default function Navigation({openSideBar, setOpenSideBar}){
    const {t, i18n} = useTranslation();
    const {user} = useContext(AuthContext)
    const defaultSideBarItem = useMemo(() => [
        // {
        //     iconProps: {
        //         svg: (
        //             <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        //                 <path d="M10.2339 4V7.09557" stroke="#323238" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
        //                 <path d="M21.5933 4V7.09557" stroke="#323238" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
        //                 <rect x="5.0592" y="5.26514" width="21.6783" height="21.6783" rx="1" stroke="#323238" stroke-width="1.8"/>
        //                 <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0028 15.1602C16.4713 15.1602 16.8511 15.5399 16.8511 16.0084L16.8511 21.0276C16.8511 21.4961 16.4713 21.8759 16.0028 21.8759C15.5343 21.8759 15.1545 21.4961 15.1545 21.0276L15.1545 16.0084C15.1545 15.5399 15.5343 15.1602 16.0028 15.1602Z" fill="#323238"/>
        //                 <path fill-rule="evenodd" clip-rule="evenodd" d="M19.3589 18.5189C19.3589 18.9874 18.9791 19.3672 18.5106 19.3672L13.4914 19.3672C13.0229 19.3672 12.6431 18.9874 12.6431 18.5189C12.6431 18.0504 13.0229 17.6706 13.4914 17.6706L18.5106 17.6706C18.9791 17.6706 19.3589 18.0504 19.3589 18.5189Z" fill="#323238"/>
        //                 <path fill-rule="evenodd" clip-rule="evenodd" d="M26.7417 9.24683H5.69519V10.9434L26.7417 10.9434V9.24683Z" fill="#323238"/>
        //             </svg>
        //
        //         )
        //     },
        //     title: t("Calendar"),
        //     link: "/calendar"
        // },
        // {
        //     iconProps: {
        //         svg: (
        //             <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        //                 <path d="M7.74445 12.78C7.74292 11.6893 7.95715 10.6091 8.3748 9.60156C8.79245 8.59405 9.40528 7.67913 10.178 6.90951C10.9507 6.13989 11.868 5.53077 12.877 5.11724C13.8861 4.70371 14.967 4.49394 16.0575 4.50001C20.6107 4.53451 24.2555 8.31798 24.2555 12.8835V13.6999C24.2555 17.8169 25.1179 20.2089 25.8768 21.5199C25.9574 21.6595 25.9999 21.8179 26 21.9791C26.0001 22.1403 25.9579 22.2987 25.8776 22.4385C25.7972 22.5783 25.6816 22.6944 25.5422 22.7754C25.4028 22.8564 25.2446 22.8993 25.0834 22.8999H6.9166C6.75541 22.8993 6.5972 22.8564 6.45781 22.7754C6.31843 22.6944 6.20277 22.5783 6.12242 22.4385C6.04208 22.2987 5.99986 22.1403 6 21.9791C6.00014 21.8179 6.04264 21.6595 6.12324 21.5199C6.8821 20.2089 7.74445 17.8169 7.74445 13.6999V12.78Z" stroke="#323238" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        //                 <path d="M12.3203 22.9003V23.8203C12.3203 24.7963 12.708 25.7323 13.398 26.4224C14.088 27.1125 15.0238 27.5002 15.9997 27.5002C16.9755 27.5002 17.9113 27.1125 18.6014 26.4224C19.2914 25.7323 19.679 24.7963 19.679 23.8203V22.9003" stroke="#323238" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        //             </svg>
        //         )
        //     },
        //     title: t("Notifications"),
        //     link: NOTIFICATIONS_PAGE_URI
        // },
        // {
        //     iconProps: {
        //         svg: (
        //             <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        //                 <path d="M16 7.15789H25.3684C26.473 7.15789 27.3684 8.05333 27.3684 9.15789V21.5789C27.3684 22.6835 26.473 23.5789 25.3684 23.5789H19.7895M16 7.15789H6.63159C5.52702 7.15789 4.63159 8.05333 4.63159 9.15789V21.5789C4.63159 22.6835 5.52702 23.5789 6.63159 23.5789H12.2105M16 7.15789V4M16 13.4737V18.5263M12.2105 14.7368V18.5263M19.7895 11.5789V18.5263M19.7895 23.5789L23.579 28M19.7895 23.5789H12.2105M12.2105 23.5789L8.42107 28" stroke="#323238" stroke-width="2" stroke-linecap="round"/>
        //             </svg>
        //         )
        //     },
        //     title: t("Statistics"),
        //     link: "/statistics"
        // },

        {
            iconProps: {
                svg: (
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.0005 21.7378C19.257 21.7378 21.897 19.1692 21.897 16.0007C21.897 12.8322 19.257 10.2637 16.0005 10.2637C12.744 10.2637 10.1041 12.8322 10.1041 16.0007C10.1041 19.1692 12.744 21.7378 16.0005 21.7378Z" stroke="#323238" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M22.8423 8.48207C23.1535 8.76096 23.4483 9.04781 23.7267 9.34263L27.0803 9.80876C27.6267 10.7321 28.0479 11.7204 28.3333 12.749L26.2941 15.3904C26.2941 15.3904 26.331 16.2032 26.2941 16.6096L28.3333 19.251C28.0492 20.2801 27.628 21.2685 27.0803 22.1912L23.7267 22.6574C23.7267 22.6574 23.1494 23.243 22.8423 23.5179L22.3632 26.7809C21.4142 27.3125 20.3985 27.7223 19.3413 28L16.6265 26.0159C16.2096 26.0518 15.7903 26.0518 15.3735 26.0159L12.6587 28C11.601 27.7236 10.5851 27.3138 9.63675 26.7809L9.15766 23.5179C8.85465 23.239 8.55983 22.9522 8.2732 22.6574L4.91961 22.1912C4.37319 21.2679 3.95202 20.2796 3.66663 19.251L5.7058 16.6096C5.7058 16.6096 5.66895 15.7968 5.7058 15.3904L3.66663 12.749C3.9507 11.7199 4.37194 10.7315 4.91961 9.80876L8.2732 9.34263C8.55983 9.04781 8.85465 8.76096 9.15766 8.48207L9.63675 5.21912C10.5857 4.68747 11.6015 4.27768 12.6587 4L15.3735 5.98406C15.7903 5.9482 16.2096 5.9482 16.6265 5.98406L19.3413 4C20.3989 4.2764 21.4148 4.68625 22.3632 5.21912L22.8423 8.48207Z" stroke="#323238" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                )
            },
            title: t("Settings"),
            hide: !checkPermission(user.roles, [RolesEnums.administrator, RolesEnums.contentManager, RolesEnums.contentReader]),
            children: [
                {
                    iconProps: {
                        svg: (
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16 4C13.6266 4 11.3066 4.70379 9.33316 6.02236C7.35977 7.34094 5.8217 9.21508 4.91345 11.4078C4.0052 13.6005 3.76756 16.0133 4.23058 18.3411C4.6936 20.6689 5.83649 22.807 7.51472 24.4853C9.19295 26.1635 11.3311 27.3064 13.6589 27.7694C15.9867 28.2324 18.3995 27.9948 20.5922 27.0865C22.7849 26.1783 24.6591 24.6402 25.9776 22.6668C27.2962 20.6934 28 18.3734 28 16C27.9966 12.8184 26.7313 9.76814 24.4816 7.51843C22.2319 5.26872 19.1816 4.00336 16 4ZM12.9573 20.6154H19.0427C18.4231 22.7315 17.3846 24.6388 16 26.1411C14.6154 24.6388 13.5769 22.7315 12.9573 20.6154ZM12.5385 18.7692C12.2323 16.9358 12.2323 15.0642 12.5385 13.2308H19.4615C19.7677 15.0642 19.7677 16.9358 19.4615 18.7692H12.5385ZM5.84616 16C5.84536 15.0636 5.97467 14.1316 6.23039 13.2308H10.6681C10.3927 15.0667 10.3927 16.9333 10.6681 18.7692H6.23039C5.97467 17.8684 5.84536 16.9364 5.84616 16ZM19.0427 11.3846H12.9573C13.5769 9.26846 14.6154 7.36115 16 5.85885C17.3846 7.36115 18.4231 9.26846 19.0427 11.3846ZM21.3319 13.2308H25.7696C26.2819 15.0414 26.2819 16.9586 25.7696 18.7692H21.3319C21.6073 16.9333 21.6073 15.0667 21.3319 13.2308ZM25.0427 11.3846H20.9546C20.4835 9.53097 19.6924 7.77382 18.6169 6.19231C19.991 6.56156 21.2726 7.21429 22.3792 8.10853C23.4859 9.00277 24.3932 10.1187 25.0427 11.3846ZM13.3831 6.19231C12.3076 7.77382 11.5165 9.53097 11.0454 11.3846H6.95731C7.60684 10.1187 8.51412 9.00277 9.62078 8.10853C10.7275 7.21429 12.009 6.56156 13.3831 6.19231ZM6.95731 20.6154H11.0454C11.5165 22.469 12.3076 24.2262 13.3831 25.8077C12.009 25.4384 10.7275 24.7857 9.62078 23.8915C8.51412 22.9972 7.60684 21.8813 6.95731 20.6154ZM18.6169 25.8077C19.6924 24.2262 20.4835 22.469 20.9546 20.6154H25.0427C24.3932 21.8813 23.4859 22.9972 22.3792 23.8915C21.2726 24.7857 19.991 25.4384 18.6169 25.8077Z" fill="#323238"/>
                            </svg>
                        )
                    },
                    title: t("Folders"),
                    link: SETTINGS_FODLERS_PAGE_URI
                },
                {
                    iconProps: {
                        svg: (
                            <svg width="32" height= "32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16 4C13.6266 4 11.3066 4.70379 9.33316 6.02236C7.35977 7.34094 5.8217 9.21508 4.91345 11.4078C4.0052 13.6005 3.76756 16.0133 4.23058 18.3411C4.6936 20.6689 5.83649 22.807 7.51472 24.4853C9.19295 26.1635 11.3311 27.3064 13.6589 27.7694C15.9867 28.2324 18.3995 27.9948 20.5922 27.0865C22.7849 26.1783 24.6591 24.6402 25.9776 22.6668C27.2962 20.6934 28 18.3734 28 16C27.9966 12.8184 26.7313 9.76814 24.4816 7.51843C22.2319 5.26872 19.1816 4.00336 16 4ZM12.9573 20.6154H19.0427C18.4231 22.7315 17.3846 24.6388 16 26.1411C14.6154 24.6388 13.5769 22.7315 12.9573 20.6154ZM12.5385 18.7692C12.2323 16.9358 12.2323 15.0642 12.5385 13.2308H19.4615C19.7677 15.0642 19.7677 16.9358 19.4615 18.7692H12.5385ZM5.84616 16C5.84536 15.0636 5.97467 14.1316 6.23039 13.2308H10.6681C10.3927 15.0667 10.3927 16.9333 10.6681 18.7692H6.23039C5.97467 17.8684 5.84536 16.9364 5.84616 16ZM19.0427 11.3846H12.9573C13.5769 9.26846 14.6154 7.36115 16 5.85885C17.3846 7.36115 18.4231 9.26846 19.0427 11.3846ZM21.3319 13.2308H25.7696C26.2819 15.0414 26.2819 16.9586 25.7696 18.7692H21.3319C21.6073 16.9333 21.6073 15.0667 21.3319 13.2308ZM25.0427 11.3846H20.9546C20.4835 9.53097 19.6924 7.77382 18.6169 6.19231C19.991 6.56156 21.2726 7.21429 22.3792 8.10853C23.4859 9.00277 24.3932 10.1187 25.0427 11.3846ZM13.3831 6.19231C12.3076 7.77382 11.5165 9.53097 11.0454 11.3846H6.95731C7.60684 10.1187 8.51412 9.00277 9.62078 8.10853C10.7275 7.21429 12.009 6.56156 13.3831 6.19231ZM6.95731 20.6154H11.0454C11.5165 22.469 12.3076 24.2262 13.3831 25.8077C12.009 25.4384 10.7275 24.7857 9.62078 23.8915C8.51412 22.9972 7.60684 21.8813 6.95731 20.6154ZM18.6169 25.8077C19.6924 24.2262 20.4835 22.469 20.9546 20.6154H25.0427C24.3932 21.8813 23.4859 22.9972 22.3792 23.8915C21.2726 24.7857 19.991 25.4384 18.6169 25.8077Z" fill="#323238"/>
                            </svg>
                        )
                    },
                    title: t("Catalogs"),
                    link: SETTINGS_CONTENT_PAGE_URI
                },
                {
                    iconProps: {
                        svg: (
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16 4C13.6266 4 11.3066 4.70379 9.33316 6.02236C7.35977 7.34094 5.8217 9.21508 4.91345 11.4078C4.0052 13.6005 3.76756 16.0133 4.23058 18.3411C4.6936 20.6689 5.83649 22.807 7.51472 24.4853C9.19295 26.1635 11.3311 27.3064 13.6589 27.7694C15.9867 28.2324 18.3995 27.9948 20.5922 27.0865C22.7849 26.1783 24.6591 24.6402 25.9776 22.6668C27.2962 20.6934 28 18.3734 28 16C27.9966 12.8184 26.7313 9.76814 24.4816 7.51843C22.2319 5.26872 19.1816 4.00336 16 4ZM12.9573 20.6154H19.0427C18.4231 22.7315 17.3846 24.6388 16 26.1411C14.6154 24.6388 13.5769 22.7315 12.9573 20.6154ZM12.5385 18.7692C12.2323 16.9358 12.2323 15.0642 12.5385 13.2308H19.4615C19.7677 15.0642 19.7677 16.9358 19.4615 18.7692H12.5385ZM5.84616 16C5.84536 15.0636 5.97467 14.1316 6.23039 13.2308H10.6681C10.3927 15.0667 10.3927 16.9333 10.6681 18.7692H6.23039C5.97467 17.8684 5.84536 16.9364 5.84616 16ZM19.0427 11.3846H12.9573C13.5769 9.26846 14.6154 7.36115 16 5.85885C17.3846 7.36115 18.4231 9.26846 19.0427 11.3846ZM21.3319 13.2308H25.7696C26.2819 15.0414 26.2819 16.9586 25.7696 18.7692H21.3319C21.6073 16.9333 21.6073 15.0667 21.3319 13.2308ZM25.0427 11.3846H20.9546C20.4835 9.53097 19.6924 7.77382 18.6169 6.19231C19.991 6.56156 21.2726 7.21429 22.3792 8.10853C23.4859 9.00277 24.3932 10.1187 25.0427 11.3846ZM13.3831 6.19231C12.3076 7.77382 11.5165 9.53097 11.0454 11.3846H6.95731C7.60684 10.1187 8.51412 9.00277 9.62078 8.10853C10.7275 7.21429 12.009 6.56156 13.3831 6.19231ZM6.95731 20.6154H11.0454C11.5165 22.469 12.3076 24.2262 13.3831 25.8077C12.009 25.4384 10.7275 24.7857 9.62078 23.8915C8.51412 22.9972 7.60684 21.8813 6.95731 20.6154ZM18.6169 25.8077C19.6924 24.2262 20.4835 22.469 20.9546 20.6154H25.0427C24.3932 21.8813 23.4859 22.9972 22.3792 23.8915C21.2726 24.7857 19.991 25.4384 18.6169 25.8077Z" fill="#323238"/>
                            </svg>
                        )
                    },
                    title: t("Widgets"),
                    link: SETTINGS_WIDGET_PAGE_URI
                },
                {
                    iconProps: {
                        svg: (
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M25.6002 4H6.39989C5.89066 4 5.4023 4.20229 5.04222 4.56236C4.68215 4.92244 4.47986 5.41081 4.47986 5.92003V12.9737C4.47986 23.7271 13.5784 27.2948 15.4 27.9008C15.7891 28.0331 16.211 28.0331 16.6 27.9008C18.4241 27.2948 27.5202 23.7271 27.5202 12.9737V5.92003C27.5202 5.41081 27.3179 4.92244 26.9579 4.56236C26.5978 4.20229 26.1094 4 25.6002 4ZM25.6002 12.9749C25.6002 22.3855 17.6381 25.5295 16 26.0767C14.3764 25.5355 6.39989 22.3939 6.39989 12.9749V5.92003H25.6002V12.9749ZM10.5208 16.1994C10.3406 16.0193 10.2394 15.7749 10.2394 15.5202C10.2394 15.2654 10.3406 15.0211 10.5208 14.841C10.7009 14.6608 10.9452 14.5596 11.2 14.5596C11.4547 14.5596 11.699 14.6608 11.8792 14.841L14.08 17.0418L20.1209 11.0009C20.2101 10.9117 20.316 10.841 20.4325 10.7927C20.5491 10.7444 20.674 10.7196 20.8001 10.7196C20.9263 10.7196 21.0512 10.7444 21.1677 10.7927C21.2842 10.841 21.3901 10.9117 21.4793 11.0009C21.5685 11.0901 21.6393 11.196 21.6875 11.3125C21.7358 11.4291 21.7607 11.554 21.7607 11.6801C21.7607 11.8063 21.7358 11.9312 21.6875 12.0477C21.6393 12.1642 21.5685 12.2701 21.4793 12.3593L14.7592 19.0794C14.6701 19.1687 14.5642 19.2395 14.4476 19.2878C14.3311 19.3361 14.2062 19.361 14.08 19.361C13.9538 19.361 13.8289 19.3361 13.7124 19.2878C13.5958 19.2395 13.49 19.1687 13.4008 19.0794L10.5208 16.1994Z" fill="#323238"/>
                            </svg>
                        )
                    },
                    title: t("Permissions"),
                    link: SETTINGS_SECURITY_PAGE_URI
                },
                // {
                //     iconProps: {
                //         svg: (
                //             <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                //                 <path d="M16 4C13.6266 4 11.3066 4.70379 9.33316 6.02236C7.35977 7.34094 5.8217 9.21508 4.91345 11.4078C4.0052 13.6005 3.76756 16.0133 4.23058 18.3411C4.6936 20.6689 5.83649 22.807 7.51472 24.4853C9.19295 26.1635 11.3311 27.3064 13.6589 27.7694C15.9867 28.2324 18.3995 27.9948 20.5922 27.0865C22.7849 26.1783 24.6591 24.6402 25.9776 22.6668C27.2962 20.6934 28 18.3734 28 16C27.9966 12.8184 26.7313 9.76814 24.4816 7.51843C22.2319 5.26872 19.1816 4.00336 16 4ZM12.9573 20.6154H19.0427C18.4231 22.7315 17.3846 24.6388 16 26.1411C14.6154 24.6388 13.5769 22.7315 12.9573 20.6154ZM12.5385 18.7692C12.2323 16.9358 12.2323 15.0642 12.5385 13.2308H19.4615C19.7677 15.0642 19.7677 16.9358 19.4615 18.7692H12.5385ZM5.84616 16C5.84536 15.0636 5.97467 14.1316 6.23039 13.2308H10.6681C10.3927 15.0667 10.3927 16.9333 10.6681 18.7692H6.23039C5.97467 17.8684 5.84536 16.9364 5.84616 16ZM19.0427 11.3846H12.9573C13.5769 9.26846 14.6154 7.36115 16 5.85885C17.3846 7.36115 18.4231 9.26846 19.0427 11.3846ZM21.3319 13.2308H25.7696C26.2819 15.0414 26.2819 16.9586 25.7696 18.7692H21.3319C21.6073 16.9333 21.6073 15.0667 21.3319 13.2308ZM25.0427 11.3846H20.9546C20.4835 9.53097 19.6924 7.77382 18.6169 6.19231C19.991 6.56156 21.2726 7.21429 22.3792 8.10853C23.4859 9.00277 24.3932 10.1187 25.0427 11.3846ZM13.3831 6.19231C12.3076 7.77382 11.5165 9.53097 11.0454 11.3846H6.95731C7.60684 10.1187 8.51412 9.00277 9.62078 8.10853C10.7275 7.21429 12.009 6.56156 13.3831 6.19231ZM6.95731 20.6154H11.0454C11.5165 22.469 12.3076 24.2262 13.3831 25.8077C12.009 25.4384 10.7275 24.7857 9.62078 23.8915C8.51412 22.9972 7.60684 21.8813 6.95731 20.6154ZM18.6169 25.8077C19.6924 24.2262 20.4835 22.469 20.9546 20.6154H25.0427C24.3932 21.8813 23.4859 22.9972 22.3792 23.8915C21.2726 24.7857 19.991 25.4384 18.6169 25.8077Z" fill="#323238"/>
                //             </svg>
                //         )
                //     },
                //     title: t("Language"),
                //     link: "/settings/language"
                // },
                // {
                //     iconProps: {
                //         svg: (
                //             <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                //                 <path d="M16.0005 21.7378C19.257 21.7378 21.897 19.1692 21.897 16.0007C21.897 12.8322 19.257 10.2637 16.0005 10.2637C12.744 10.2637 10.1041 12.8322 10.1041 16.0007C10.1041 19.1692 12.744 21.7378 16.0005 21.7378Z" stroke="#323238" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                //                 <path d="M22.8423 8.48207C23.1535 8.76096 23.4483 9.04781 23.7267 9.34263L27.0803 9.80876C27.6267 10.7321 28.0479 11.7204 28.3333 12.749L26.2941 15.3904C26.2941 15.3904 26.331 16.2032 26.2941 16.6096L28.3333 19.251C28.0492 20.2801 27.628 21.2685 27.0803 22.1912L23.7267 22.6574C23.7267 22.6574 23.1494 23.243 22.8423 23.5179L22.3632 26.7809C21.4142 27.3125 20.3985 27.7223 19.3413 28L16.6265 26.0159C16.2096 26.0518 15.7903 26.0518 15.3735 26.0159L12.6587 28C11.601 27.7236 10.5851 27.3138 9.63675 26.7809L9.15766 23.5179C8.85465 23.239 8.55983 22.9522 8.2732 22.6574L4.91961 22.1912C4.37319 21.2679 3.95202 20.2796 3.66663 19.251L5.7058 16.6096C5.7058 16.6096 5.66895 15.7968 5.7058 15.3904L3.66663 12.749C3.9507 11.7199 4.37194 10.7315 4.91961 9.80876L8.2732 9.34263C8.55983 9.04781 8.85465 8.76096 9.15766 8.48207L9.63675 5.21912C10.5857 4.68747 11.6015 4.27768 12.6587 4L15.3735 5.98406C15.7903 5.9482 16.2096 5.9482 16.6265 5.98406L19.3413 4C20.3989 4.2764 21.4148 4.68625 22.3632 5.21912L22.8423 8.48207Z" stroke="#323238" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                //             </svg>
                //         )
                //     },
                //     title: t("Features and tips"),
                //     link: "/settings/features-and-trips"
                // },
                // {
                //     iconProps: {
                //         svg: (
                //             <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                //                 <path d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z" stroke="#323238" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                //                 <path d="M16 24C16.8284 24 17.5 23.3284 17.5 22.5C17.5 21.6716 16.8284 21 16 21C15.1716 21 14.5 21.6716 14.5 22.5C14.5 23.3284 15.1716 24 16 24Z" fill="#323238"/>
                //                 <path d="M16 18V17C16.6922 17 17.3689 16.7947 17.9445 16.4101C18.5201 16.0256 18.9687 15.4789 19.2336 14.8394C19.4985 14.1999 19.5678 13.4961 19.4327 12.8172C19.2977 12.1383 18.9644 11.5146 18.4749 11.0251C17.9854 10.5356 17.3617 10.2023 16.6828 10.0673C16.0039 9.9322 15.3001 10.0015 14.6606 10.2664C14.0211 10.5313 13.4744 10.9799 13.0899 11.5555C12.7053 12.1311 12.5 12.8078 12.5 13.5" stroke="#323238" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                //             </svg>
                //         )
                //     },
                //     title: t("Q&A"),
                //     link: "/settings/faq"
                // },
            ]
        },
    ], [i18n.language])
    const {medicalCard} = useContext(AuthContext)
    const {setGlobalFolder} = useContext(GlobalFolderContext)
    const [sideBarItems, setSideBarItems] = useState(defaultSideBarItem);
    const [sideBarFolderItems, setSideBarFolderItems] = useState([]);
    const [currentFolder, setCurrentFolder] = useState(null);

    const setCurrentFolderHandler = (folder) => {
        setGlobalFolder(folder.folder)
    }

    const getMedicalCardWithoutRecord = async (params = {}) => {
        try {
            const folderForNavigation = medicalCard.folder.filter(folder => folder.incarnation.id === IncarnationIdEnum.id.cardHolder)
            const folders = ParseMedicalCardApi.formatFoldersWithChildren(folderForNavigation)

            setSideBarFolderItems(folders.map( folder => {
                const sideBarItem = {
                    ...formatFolderToSideBarItems(folder, setCurrentFolderHandler),
                    children: folder.childrenFolder?.map(folder => formatFolderToSideBarItems(folder, setCurrentFolderHandler)) || null
                }

                if(sideBarItem.children){
                    delete sideBarItem.link
                    delete sideBarItem.hash
                }

                return sideBarItem
            }))
        }
        catch (e){
            setSideBarFolderItems([])
        }
    };

    useEffect(() => {
        getMedicalCardWithoutRecord()
    }, [medicalCard])

    useEffect(() => {
        setSideBarItems(defaultSideBarItem)
    }, [defaultSideBarItem])

    return (
        <div className={`${styles.wrapper} ${!openSideBar ? styles.close : ""}`}>
            <SideBarTop setOpenSideBar={setOpenSideBar} openSideBar={openSideBar}/>
            <SideBar sideBarItems={[...sideBarFolderItems, ...sideBarItems]} openSideBar={openSideBar}/>
        </div>
    )
}
