/**
 * external libs
 */
import React, {useEffect, useRef, useState} from 'react'
import {Input} from "antd";
/**
 * styles
 */
import styles from './index.module.scss'
/**
 * enums
 */
import ResultKeysEnum from "../../../../../../enums/api/ResultKeys";
import FieldItem from "../../../../../Form/components/FieldItem";
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import ReactQuill from 'react-quill';
import {EditorState, convertToRaw, SelectionState, Modifier, convertFromRaw} from 'draft-js';
import 'react-quill/dist/quill.snow.css';
import {draftToMarkdown, markdownToDraft} from 'markdown-draft-js';
import {useTranslation} from "react-i18next";

const newEditorWithReplacedWord = (editorState, wordToReplace, replacementWord) => {
    let contentState = editorState.getCurrentContent();

    contentState.getBlockMap().forEach((block) => {
        const blockKey = block.getKey();
        let blockText = block.getText();

        let startIndex = blockText.indexOf(wordToReplace);
        while (startIndex !== -1) {
            const selection = SelectionState.createEmpty(blockKey).merge({
                anchorOffset: startIndex,
                focusOffset: startIndex + wordToReplace.length,
            });

            // Replace the "*" with the replacement (***)
            contentState = Modifier.replaceText(contentState, selection, replacementWord);

            // Update the blockText after the replacement
            blockText = contentState.getBlockForKey(blockKey).getText();

            // Move past the newly replaced text (use replacementWord length)
            startIndex = blockText.indexOf(wordToReplace, startIndex + replacementWord.length);
        }
    });

    return EditorState.push(editorState, contentState, 'change-block-data')
};


export default function EditableMarkdownWidget({
                                                   isFocused,
                                                   widget,
                                                   resultValue,
                                                   onEdit,
                                                   validationErrorMessage,
                                                   field,
                                                   onBlur,
                                                   result
                                               }) {
    const [resultData, setResultData] = useState(result)
    const [openToolbar, setOpenToolbar] = useState(false)
    const [editorState, setEditorState] = useState(EditorState.createWithContent(convertFromRaw(markdownToDraft(resultValue))))

    const onChangeHandler = (newEditorState) => {
        setEditorState(newEditorState)
        const editorState = newEditorWithReplacedWord(newEditorState, "*", "&ast;")
        const value = draftToMarkdown(convertToRaw(editorState.getCurrentContent())) || null;

        const newMeasuresValue = field ? {
            ...resultData.measures,
            [field]: value,
        } : value

        setResultData({
            ...resultData,
            is_modified: true,
            measures: newMeasuresValue
        })
        onEdit({
            ...resultData,
            is_modified: true,
            measures: newMeasuresValue
        })
    }

    const focusHandler = () => {
        const contentState = editorState.getCurrentContent();
        const selection = new SelectionState({
            anchorKey: contentState.getFirstBlock().getKey(),
            anchorOffset: 0,
            focusKey: contentState.getLastBlock().getKey(),
            focusOffset: contentState.getLastBlock().getLength(),
        });
        setEditorState(EditorState.forceSelection(editorState, selection));
    }

    useEffect(() => {
        if (isFocused) {
            focusHandler()
        }
    }, [isFocused])

    return (
        <FieldItem label={widget.description || ""}
                   required={!widget.type.includes("null")}
                   requiredColor={resultValue ? "#1084FF" : "red"}
                   stylesWrapper={{marginBottom: 10}} error={validationErrorMessage}>
            <div className={styles.wrapper} style={{maxWidth: 500, width: 500}}>
                <div className={styles.wrapper__toolbarBtn} onClick={() => setOpenToolbar(!openToolbar)}>
                    {openToolbar ? (
                        <img src="/image/icon/toolbarDown.svg" alt=""/>
                    ) : (
                        <img src="/image/icon/toolbarUp.svg" alt=""/>
                    )}
                </div>
                <Editor
                    toolbarHidden={!openToolbar}
                    editorState={editorState}
                    defaultEditorState={editorState}
                    onFocus={focusHandler}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    toolbar={{
                        options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
                    }}
                    onEditorStateChange={onChangeHandler}
                />
            </div>
        </FieldItem>
    )


    // return (
    //     <FieldItem label={widget.description || ""} stylesWrapper={{marginBottom: 10}} error={validationErrorMessage}>
    //         <div className={styles.wrapper}>
    //             <ReactQuill theme="snow" defaultValue={resultValue || ""} onChange={onChangeHandler} />
    //         </div>
    //     </FieldItem>
    // )
}
