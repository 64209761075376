/**
 * external libs
 */
import React, {useContext, useState} from 'react'
/**
 * components
 */
import Navigation from '../Navigation'
/**
 * styles
 */
import styles from './index.module.scss'
import {AuthContext} from "../../pages/context/auth.context";
import StorageService from "../../services/storage.service";

export default function Layout({ children }){
    const [openSideBar, setOpenSideBar] = useState(StorageService.navigationState);
    const {user} = useContext(AuthContext)

    const setOpenSideBardHandler = ( openSideBar ) => {
        if(openSideBar){
            StorageService.navigationState = true
            setOpenSideBar(true)
            return;
        }

        StorageService.removeNavigationState();
        setOpenSideBar(false)
    }

    if(!user){
        return (
            <div className={{minHeight: "100vh"}}>
                <div style={{width: "100%", display: "flex", flexDirection: "column"}}>
                    {children}
                </div>
            </div>
        )
    }

    return (
        <div className={styles.layout}>
            <div style={{display: "flex"}}>
                <Navigation setOpenSideBar={setOpenSideBardHandler} openSideBar={openSideBar}/>
            </div>
            <div style={{width: `calc(100% - ${openSideBar ? "300" : "84"}px)`, display: "flex", flexDirection: "column"}}>
                {children}
            </div>
        </div>
    )
}
