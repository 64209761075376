export default function enTranslate() {
    return {
        "Calendar": "Calendar",
        "Notifications": "Notifications",
        "Statistics": "Statistics",
        "Settings": "Settings",
        "Create record": "Create record",
        "Security": "Security",
        "Copy": "Copy",
        "Search": "Search",
        "Language": "Language",
        "Create card": "Create card",
        "Remove": "Remove",
        "Edit record": "Edit record",
        "Unassigned": "Unassigned",
        "File downloaded!": "File downloaded!",
        "Go to widget": "Go to widget",
        "Features and tips": "Features and tips",
        "Q&A": "Q&A",
        "Count": "Count",
        "Share": "Share",
        "Yes, I\'m sure I want to delete my account and all associated data": "Yes, I\'m sure I want to delete my account and all associated data",
        "Delete all data": "Delete all data",
        "claimed": "claimed",
        "received": "received",
        "Contact information": "Contact information",
        "Pathomorphological Diagnosis": "Pathomorphological Diagnosis",

        "Histopathological report": "Histopathological report",
        "Pathomorphological report": "Pathomorphological report",
        "Pathomorphological Examination": "Pathomorphological Examination",
        "Date of Biopsy": "Date of Biopsy",
        "Date of Admission": "Date of Admission",
        "Date of Conclusion": "Date of Conclusion",
        "Imunohistochemical Profile": "IHC profile",
        "Molecular Genetic Profile": "Molecular Genetic Profile",
        "Number of Containers": "Number of Containers",
        "Medical card": "Medical card",
        "H/B": "H/B",
        "Date of Birth": "Date of Birth",
        "Date of Birth short": "D/B",
        "Age": "Age",
        "Patient": "Patient",
        "Sex": "Sex",
        "Contacts": "Contacts",
        "Medical institution": "Medical institution",
        "Department": "Department",
        "Treating doctor": "Treating doctor",
        "Doctor Contacts": "Doctor Contacts",
        "Clinical diagnosis": "Clinical diagnosis",
        "Clinical data": "Clinical data",
        "Macro": "Macro",
        "Macroscopy": "Macroscopy",
        "Microscopy": "Microscopy",
        "Material type": "Material type",
        "Fixation": "Fixation",
        "Number of blocks": "Number of blocks",
        "Decalcification": "Decalcification",
        "Macro archive": "Macro archive",
        "Dyeing": "Dyeing",
        "Micro": "Micro",
        "Pathological diagnosis": "Pathological diagnosis",
        "Comment": "Comment",
        "Pathologist": "Pathologist",
        "Print slide QR": "Print slide QR",
        "Label": "Label",
        "Relation": "Relation",
        "Validity period": "Validity period",
        "Share link": "Share link",
        "Forever": "Forever",
        "Year": "Year",
        "Month": "Month",
        "Week": "Week",
        "Can view": "Can view",
        "Can edit": "Can edit",
        "Can reshare": "Can reshare",
        "Copied": "Copied",
        "Share this medical card": "Share this medical card",
        "Scan QR": "Scan QR",
        "User agreement": "User agreement",
        "Update the app": "Update the app",
        "Add glass QR code": "Add glass QR code",
        "Create glass QR code": "Create glass QR code",
        "Time": "Time",
        "All notifications": "All notifications",
        "Date": "Date",
        "Value": "Value",
        "Default": "Default",
        "Diagnosis": "Diagnosis",
        "Date of visits": "Date of visits",
        "Status": "Status",
        "Roles": "Roles",
        "Create Permission": "Create Permission",
        "Permissions": "Permissions",
        "Full name": "Full name",
        "Translation Warnings:": "Translation Warnings:",
        "Copy to clipboard": "Copy to clipboard",
        "Gender and age": "Gender and age",
        "Add account": "Add account",
        "Page not found": "Page not found",
        "Select data": "Select data",
        "Select date": "Select date",
        "Log out": "Log out",
        "Permit": "Permit",
        "Included": "Included",
        "Enabled": "Enabled",
        "Enable": "Enable",
        "Reset Password": "Reset Password",
        "Excluded": "Excluded",
        "Permitted": "Permitted",
        "Action": "Action",
        "y.o.": "y.o.",
        "m.": "m.",
        "w.": "w.",
        "d.": "d.",
        "Create result": "Create result",
        "Yes": "Yes",
        "No": "No",
        "Add files": "Add files",
        "Add file": "Add file",
        "Take picture": "Take picture",
        "Paste link to file": "Paste link to file",
        "Paste from clipboard": "Paste from clipboard",
        "Policy Pages": "Policy Pages",
        "User Agreement": "User Agreement",
        "Personal info": "Personal info",
        "Add field": "Add field",
        "Insert into record": "Insert into record",
        "Insert": "Insert",
        "Add": "Add",
        "Go to record": "Go to record",
        "Permitted members": "Permitted members",
        "Failed to save data.": "Failed to save data.",
        "Data saved successfully!": "Data saved successfully!",
        "Translations": "Translations",
        "Edit": "Edit",
        "Are you sure you want to delete this result?": "Are you sure you want to delete this result?",
        "Are you sure you want to delete this record?": "Are you sure you want to delete this record?",
        "Are you sure you want to delete this medical card?": "Are you sure you want to delete this medical card?",
        "You successfully removed medical card": "You successfully removed medical card",
        "The medical record you are trying to delete does not exist!": "The medical record you are trying to delete does not exist!",
        "There are no records in this folder yet.": "There are no records in this folder yet.",
        "Delete": "Delete",
        "Fill in required fields!": "Fill in required fields!",
        "You must fix the validation error": "You must fix the validation error",
        "ValidationMessage.minimum": "Value must be grater or equal then {{ error.argument }}",
        "ValidationMessage.maximum": "Value must be less or equal then {{ error.argument }}",
        "ValidationMessage.exclusiveMinimum": "Value must be grater then {{ error.argument }}",
        "ValidationMessage.exclusiveMaximum": "Value must be less then {{ error.argument }}",
        "ValidationMessage.multipleOf": "Value must be a multiple of {{ error.argument }}",
        "ValidationMessage.minLength": "Value must be grater or equal then {{ error.argument }}",
        "ValidationMessage.maxLength": "Value must be less or equal then {{ error.argument }}",
        "ValidationMessage.pattern": "Invalid value",
        "ValidationMessage.type": "The field is required",
        "ValidationMessage.format": 'Does not conform to the "{{ error.argument }}" format',
        "The result must have at least one member": "The result must have at least one member",
        "Privacy Policy": `
        <style>
            strong {
                font-weight: bold;
            }
        </style>
        <p style="text-align: center;"><strong><span style="font-size:12pt;">USER AGREEMENT</span></strong></p>
    <p><br></p>
    <p><span style="font-size:12pt;">The User Agreement set out below (hereinafter referred to as the &quot;Agreement&quot;) is addressed to an undefined circle of persons, users of COR-Lab (available at <a href="https://cor-medical.ua/platform"><u><span style="font-size:12pt;">https://cor-medical.ua/platform</span></u></a> and/or as a COR-Lab mobile application in Google Play, App Store and Huawei AppGallery) and determines the procedure for using COR-Lab. The person who owns exclusive property rights to COR-Lab, including those necessary and sufficient for its administration and providing access to the system, is &quot;COR-SOFTWARE&quot; Limited Liability Company, legal entity identification code 44812910 (hereinafter - COR-Lab). This Agreement is a contract of accession (public offer) within the meaning of Art. 633, 634 of the Civil Code of Ukraine. Use of COR-Lab is considered unconditional acceptance of this Agreement.</span></p>
    <p><br></p>
    <p style="text-align: center;"><strong><span style="font-size:12pt;">1. Terms&nbsp;</span></strong></p>
    <p><br></p>
    <p><span style="font-size:12pt;">1.1. COR-Lab - software products, as a result of computer programming, intended for the organization of the process of providing medical assistance and/or medical services, the servers of which are located in the protected hardware and software platform of the &quot;cloud&quot; data processing center, which is accessed via using the website located on the Internet at <a href="https://cor-medical.ua/platform"><u><span style="font-size:12pt;">https://cor-medical.ua/platform</span></u></a> and/or the COR-Lab mobile application, which is available for download in Google Play, App Store and Huawei App Gallery, which is provided for personal use users.</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">1.2. The user is a natural person who has full civil capacity and legal capacity and uses COR-Lab (without the purpose of making a profit), regardless of whether or not he has an account.&nbsp;</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">1.3. An account is a set of information about the user (a person whose legal representative he is) and authorization data (phone number, login, password) entered by the user in COR-Lab during registration and during system use.&nbsp;</span></p>
    <p><br></p>
    <p style="text-align: center;"><strong><span style="font-size:12pt;">2. General terms of use of COR-Lab</span></strong></p>
    <p><br></p>
    <p><span style="font-size:12pt;">2.1 COR-Lab can be used only by users who have accepted all the terms of this Agreement, the current version of which is posted at <a href="https://cor-medical.ua/platform"><u><span style="font-size:12pt;">https://cor-medical.ua/platform</span></u></a> or in the mobile application, and for certain services and system functionalities, other agreements as well and consents, about which users must be notified with the opportunity to familiarize themselves with the content of such documents and make a decision on their further use or refusal to use them.</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">2.2 Before starting to use COR-Lab, the user must familiarize himself with the Agreement and accept its terms without further signing it by the user. If the user does not agree to the terms of this agreement, he may not use the COR-Lab software products.&nbsp;</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">2.3. The user can register with COR-Lab by creating a personal account or register a person for whom he is a legal representative (parents (adoptive parents-caregivers, adoptive parents, foster carers), guardians, custodians, representatives of institutions, other persons who perform the functions of guardians and guardians, etc.).&nbsp;</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">2.4. Registration in COR-Lab is possible only if the user gives consent to the processing of his personal data (or the personal data of the person whose legal representative he is). This is due to the fact that COR-Lab services cannot be provided in full and in full to unidentified users. However, a large amount of information is available for viewing by all users regardless of registration. For certain actions with information that poses a particular risk to the rights and freedoms of personal data subjects (for example, about health), user verification (verification by a family doctor/medical registrar of documents) or confirmation using a qualified electronic signature of the user may be required.&nbsp;</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">2.5. The terms of this Agreement apply to all users of COR-Lab - both registered and non-registered.&nbsp;</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">2.6. If the user does not agree to the terms of this Agreement, he must stop using COR-Lab.</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">2.7. The User is prohibited to decompile COR-Lab software products independently or with the involvement of third parties, reverse engineer or otherwise convert any software related to COR-Lab software products into a readable form.</span></p>
    <p><br></p>
    <p style="text-align: center;"><strong><span style="font-size:12pt;">3. COR-Lab capabilities</span></strong></p>
    <p><br></p>
    <p><span style="font-size:12pt;">3.1. COR-Lab is intended for the organization of the process of providing medical care and/or medical services. We draw your attention to the fact that we do not directly participate in the provision of medical care or medical services, do not influence the work of health care institutions, laboratories, pharmacies, individual specialists, etc., and do not control their activities. &quot;COR-SOFTWARE&quot; LLC does not have any grounds or rights to give them any instructions, monitor the availability of drugs or demand their provision, influence the schedule of reception, etc. &quot;COR-SOFTWARE&quot; LLC exclusively provides a platform that, at the request of both parties, can be used for communication between health care facilities (laboratories, pharmacies or individual specialists, etc.) and you as a patient or consumer of services.</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">3.2. You choose the COR-Lab services you want to use. This can be, for example, making an appointment with a doctor, maintaining an electronic medical record, signing a declaration with a primary care doctor, drawing up insurance contracts, storing information, providing recommendations or information, etc.</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">3.3. COR-Lab stores only the information that was entered into the system by you personally (including actions taken by you in the system) or by specialists to whom you turned for medical assistance and/or medical services, and who use COR-Lab Medical.</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">3.4. In the case of automated transfer of copies of digital documents using any third-party web portal of electronic services, such transfer takes place solely on the basis of your consent (using a mobile application) in accordance with the procedure defined by current legislation. Your personal data is received by COR-SOFTWARE LLC for the purpose specified in this Notice from the Technical Administrator of a third-party web portal of electronic services, which transfers rights to us in the manner specified by current legislation.&nbsp;</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">4. All information published on COR-Lab is for reference only, namely:&nbsp;</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">4.1. COR-Lab cooperates with hospitals, doctors and laboratories that have the appropriate license to carry out economic activities from medical practice. With COR-Lab, they provide potential patients with information about themselves, their hours of operation, available services, and more. You can view such information without registration or authorization. If COR-Lab has a booking function available for a particular facility, this means that they can use our system to receive information about the user&apos;s chosen appointment time. Such institutions independently determine the time slots and specialists to whom an appointment is possible, as well as independently monitor the implementation of appointments by appointment. We, for our part, provide a platform for your communication with hospitals, doctors and laboratories and do not and cannot influence your relationship with them, but we do our best to obtain guarantees of the integrity of our partners. The recording function is only available to registered and authorized users.</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">4.2. COR-Lab cooperates with pharmacies that have a license to carry out the activity of retail trade of medicinal products. With the help of COR-Lab, they provide their potential customers (consumers) with reference information about the products they sell. This allows you to quickly receive the necessary, accessible, reliable and timely information about the necessary goods (including medical ones). Such background information is not advertising and is used by pharmacies (as sellers) to provide consumer information about products which provides the possibility of her conscious and competent choice before purchasing the goods. Information to the consumer must be provided in accordance with the legislation on languages.</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">4.4. When you enter the name of a medicine in the search field, you give your consent to receive information about such products by means of remote communication.&nbsp;</span></p>
    <p><span style="font-size:12pt;">Please note that COR-Lab does not sell medicinal products. In case of delivery or purchase of goods (including medical purposes) from COR-Lab, such order is received and fulfilled by the relevant pharmacy.</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">4.5. We provide a platform for your communication with pharmacies and obtaining reference information about products. Reservation of any goods, setting prices for them, registration and delivery of orders (goods), payment, etc. are carried out exclusively by pharmacies that sell the goods chosen by you. We do not and cannot influence your legal relationship with them (as a seller and consumer), but we do our best to obtain guarantees of the integrity of our partners, the search for medicinal products, information about such products and their sellers is available without registration or authorization. To make a reservation, you will need to provide your phone number and name, and to order delivery - your last name, first name, patronymic, phone number and delivery address (which are transferred to the relevant pharmacy exclusively for delivery and are not stored in the future). If you want to track the status of your order and save your order history, you will need to register or authorize and place an order from your COR-Lab account.</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">4.6. To simplify the process of signing of insurance contracts, we cooperate with insurance companies. At COR-Lab, you can familiarize yourself with the terms of insurance from our partners, get answers to the most common questions about insurance products, and draw up an insurance contract. Please note that if you wish to conclude an insurance contract, you will be asked to sign a separate agreement that will record your consent to such actions. Registration or authorization is not required to view information; however, only authorized users can purchase insurance.</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">4.7. At COR-Lab, it is possible to get an online doctor&apos;s consultation (online appointment). Such consultations are provided by health care institutions (doctors who work in them) or by individual entrepreneurs who have received a license to conduct economic activity from medical practice. COR-Lab does not provide, does not influence and does not control the provision of online consultations, but only ensures the functioning of a convenient platform for your communication with doctors. COR-Lab is not responsible for the timing, content, relevance and quality of providing online consultations, as well as for possible negative consequences and damages caused as a result of providing, not providing or improperly providing online consultations. Online consultations are available only to registered and authorized users.</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">4.8. COR-Lab is connected to the central database of the electronic health care system and allows you to make declarations about the choice of a doctor who provides primary medical care, if the hospital (doctor) with which you want to make a declaration uses COR-Lab.</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">4.9. In addition, COR-Lab offers a wide range of functionality. An up-to-date list of which can be found on the website at the link&nbsp;</span> <a href="https://cor-medical.ua/platform"><u><span style="font-size:12pt;">https://cor-medical.ua/platform</span></u></a> <span style="font-size:12pt;">&nbsp;</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">4.10. The conditions and features of using these and other services can also be specified at <a href="https://cor-medical.ua/platform"><u><span style="font-size:12pt;">https://cor-medical.ua/platform</span></u></a> (or in the mobile application) on separate pages and in the notice on the processing of personal data.&nbsp;</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">4.11. Information about the user (the person whose legal representative he is) is stored in COR-Lab only in the case of creating an account and is entered by the user personally, by his doctor or persons to whom the user (the person whose legal representative he is) turned to for medical assistance and/or medical services. An electronic medical card of the user (the person whose legal representative he is) can be formed from this information. All cases of use or transfer of such information are detailed in the notice on processing of personal data.</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">4.12. &quot;COR-SOFTWARE&quot; LLC has the right to disclose personal data without the user&apos;s consent only in the cases defined by law and in the interests of national security, economic well-being and human rights, in particular, but not exclusively, at the justified requests of government bodies that have the right to demand and receive such data and information.</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">4.15. Our partners (pharmacies, insurance companies, etc.) can provide you with paid services (sale of goods, delivery, etc.) in accordance with the conditions stipulated in clause 3.4 of this Agreement. Payment in such cases is made directly to our partners - service providers (pharmacies, insurance companies, sellers of goods, etc.) using a bank card. At the same time, you automatically go to the site of the corresponding payment system and enter your personal data and bank card data directly on the site of this payment system. COR-Lab does not store or transfer users&apos; bank card data, nor does it take part in making payments. Acceptance and transfer of payments using bank cards are carried out by the relevant payment systems authorized to provide the relevant services.</span></p>
    <p><br></p>
    <p style="text-align: center;"><strong><span style="font-size:12pt;">5. Rights and obligations of the COR-Lab user</span></strong></p>
    <p><br></p>
    <p><span style="font-size:12pt;">5.1. COR-Lab&apos;s activities concern one of the most serious areas of every person&apos;s life &mdash; health. Entering false or inaccurate information into the system can have serious consequences for users (the person whose legal representative he is). That is why we urge you to be responsible and immediately stop using COR-Lab if you are not ready to comply with the following conditions:&nbsp;</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">5.1.1. the user of COR-Lab guarantees that only true and up-to-date data will be entered into COR-Lab;&nbsp;</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">5.1.2. in case of entering medical information, the user confirms its veracity and understanding of possible negative and dangerous consequences in case of entering false information;&nbsp;</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">5.1.3. all information entered in COR-Lab will be kept up-to-date by the user, that is, if necessary, he will change or delete outdated information and enter new data;&nbsp;</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">5.1.4. the user will immediately notify the COR-Lab team of the loss or change of the telephone number (as one of the means of authentication at COR-Lab);&nbsp;</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">51.5. the user confirms that he will take all possible adequate measures to keep passwords for entering COR-Lab (including one-time passwords and passwords) confidential. Such measures include: not disclosing passwords to third parties, logging into COR-Lab using secure devices, etc.;</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">5.1.6. the user confirms his understanding that he is fully responsible for the actions taken in COR-Lab using the means of authentication chosen by him (including phone number, passwords, one-time passwords and password words);&nbsp;</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">5.1.7. any actions performed using the user&apos;s account and/or his login and password, entered phone number, are considered to have been performed by this user, unless proven otherwise. In case of loss of passwords for entering COR-Lab (including one-time passwords and passwords), the user shall immediately inform the COR-Lab team.&nbsp;</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">5.1.8. in case of termination of use of COR-Lab or unwillingness to keep information up-to-date, the user immediately submits a request for deletion of personal data by sending a letter to our e-mail info@cor-medical.com, through the feedback form at https://cor -medical.ua/platform or submitting an application in paper form by letter (to the address: 12, Anton Tsedik Str., Kyiv, Ukraine, 03057).</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">5.1.9. If the user believes that COR-Lab has information that violates his rights (or the person whose legal representative he is), the user is obliged to notify &quot;COR-SOFTWARE&quot; LLC about this and provide information that confirms such a violation.&nbsp;</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">5.2. If we receive information, confirmed by relevant evidence, about the introduction of inaccurate information into the system, violation of this Agreement when using COR-Lab, use for registration of means of communication belonging to third parties or registration of third parties without sufficient authority, LLC &quot; COR -SOFTWARE &rdquo; reserves the right, without prior notice, to block the account in respect of which such information was found, for the duration of the verification.&nbsp;</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">5.3. According to the results of the check, in the case of establishing the fact of entering inaccurate information into the system, violation of this Agreement when using COR-Lab, use of communication means belonging to third parties for registration or registration of third parties without sufficient authority (or the inability to refute such facts), a decision may be made to delete inaccurate information or to delete the account in respect of which such facts were discovered, in general without the possibility of further recovery (in the case of entering inaccurate data regarding means of communication (which are used as one of the identifiers in COR-Lab), Full name, or if the volume of inaccurate information left after deletion does not allow the creation of a user account), which the person who created such an account can be notified about if we have information about his means of communication.</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">5.4. To ensure the high-quality work of COR-Lab, we may impose restrictions on certain user actions or their number.&nbsp;</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">5.5. &quot;COR-SOFTWARE&quot; LLC has the right to limit, suspend or terminate the use of the Application without prior notice in the event of a corresponding decision of the state or other authorized body, or in other cases provided for by the current legislation of Ukraine and international rules/laws.&nbsp;</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">5.6. &quot;COR-SOFTWARE&quot; LLC is not responsible for the content of the information entered in COR-Lab by the user, his doctor or persons to whom the user turned for medical assistance and/or medical services and who use COR-Lab.</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">5.7. &quot;COR-SOFTWARE&quot; LLC does not compensate for direct or indirect damage (including damage, additional costs, lost profits, moral damage) caused to the user or third parties as a result of use or non-use, including inability to use COR-Lab for any reason.</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">5.8. This is due to the fact that directly medical care and/or medical services, sale of medicines, etc. are not carried out by us and their receipt is possible in full without using COR-Lab.</span></p>
    <p><br></p>
    <p style="text-align: center;"><strong><span style="font-size:13pt;">6. Use of cookies and statistical information</span></strong></p>
    <p><br></p>
    <p><span style="font-size:12pt;">6.1. For your convenience, COR-Lab uses cookies. Cookies are small text files that are stored when you visit almost all web pages. This data cannot be linked to individual users as it is collected anonymously.&nbsp;</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">6.2. This data is necessary for more convenient use of COR-Lab, setting privacy settings, safe search, filling out forms to facilitate registration of new services and protecting your data.&nbsp;</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">6.3. With the help of cookies, we collect statistical information about the use of COR-Lab, improve the user environment, track the use of services or tools in the online format, the number of visits and traffic sources.&nbsp;</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">6.4. All information collected by cookies is general by audience, that is, the data is not personalized and 100% anonymous. We use the following types of cookies: Functional: such cookies are necessary for more convenient use of COR-Lab, setting of privacy settings, secure search, filling out forms to facilitate registration of new services and protection of your data.&nbsp;</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">Analytical: with the help of such cookies, we collect statistical information about the use of COR-Lab, improve the user environment, track the use of online services or tools, the number of visits and traffic sources.&nbsp;</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">Also, to improve the experience of using web services and other sites, for the purpose of targeting and analytics of advertising campaigns, cookies of the &quot;3rd party&quot;;&nbsp;</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">After the user has given prior consent in the application, we can use advertising identifiers - unique variable identifiers for advertising (provided by Google Play, AppStore and Huawei Ads publisher service). Such identifier is not associated with any personally identifiable information or persistent device identifiers (such as SSAID, MAC address, IMEI, etc.).</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">6.5. It is also possible to use COR-Lab without using cookies. You can turn them off at any time on your device or adjust them according to your needs. The advertising identifier can be reset by you at any time in the system settings of your device.&nbsp;</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">6.6. Any statistical information collected by us is completely anonymous and excludes the possibility of identification of persons whose activity information could be included in such statistics.&nbsp;</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">We may share anonymous information about the use of COR-Lab, current user needs (necessary services, services, etc.), number of visits, etc. to our partners to better understand the needs of the healthcare and medical information systems.</span></p>
    <p><br></p>
    <p style="text-align: center;"><strong><span style="font-size:12pt;">7. Other conditions</span></strong></p>
    <p><br></p>
    <p><span style="font-size:12pt;">7.1. &quot;COR-SOFTWARE&quot; LLC has the right to make changes to this Agreement without any separate and/or special notice to the user.&nbsp;</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">All changes made to the Agreement will be available in the form of a new version of the Agreement posted on the website <a href="https://cor-medical.ua/platform"><u><span style="font-size:12pt;">https://cor-medical.ua/platform</span></u></a> or in the mobile application.&nbsp;</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">7.2. All changes to the Agreement become effective from the moment of their publication on the website <a href="https://cor-medical.ua/platform"><u><span style="font-size:12pt;">https://cor-medical.ua/platform</span></u></a> or in the mobile application.&nbsp;</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">7.3. By using COR-Lab, the user confirms that he has learned and agrees to the terms of the Agreement as amended at the time of using COR-Lab.</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">7.4. Entering a phone number or other means of communication in COR-Lab means giving the user the right to send him information messages, i.e. calls, SMS, e-mail, messenger messages, etc. This is necessary for registration, authorization, confirmation of certain actions in COR-Lab or obtaining information that may be necessary or interesting to the user in the field of medicine and pharmacology/pharmacy.&nbsp;</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">7.5. All possible disputes arising from the relations regulated by this Agreement shall be resolved in accordance with the procedure established by the legislation of Ukraine, according to the norms of Ukrainian law. Everywhere in the text of this Agreement, unless clearly stated otherwise, the term &quot;applicable legislation&quot; means the applicable legislation of Ukraine.&nbsp;</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">7.6. None of the Parties to the Agreement shall bear responsibility for non-fulfillment or improper fulfillment of obligations under this Agreement, if proper fulfillment was impossible due to force majeure.&nbsp;</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">7.7. This Agreement is drawn up in Ukrainian and English.&nbsp;</span></p>
    <p><br></p>
    <p><span style="font-size:12pt;">7.8. If any provision of this Agreement is held invalid, the validity or enforceability of the other provisions of this Agreement shall not be affected.</span></p>`,
        "Notification Page": `
    <style>
        strong {
            font-weight: bold;
        }
    </style>
    <p style="text-align: center;"><strong><span style="font-size:11pt;">NOTICE ON PERSONAL DATA PROCESSING</span></strong></p>
<p><br></p>
<p><span style="font-size:11pt;">In accordance with Clauses 1 and 2 of the second part of Article 8, the second part of Article 12 of the Law of Ukraine &quot;On the Protection of Personal Data&quot;, the Limited Liability Company &quot;COR-SOFTWARE&quot; informs about the owner, administrator, location, composition and purpose of collection of personal data processed by software tools in the &quot;COR-SOFTWARE&quot; information and communication system (including the COR-Lab mobile application), third parties to whom such personal data is transferred, and the rights of the subject of personal data.&nbsp;=</span></p>
<p><br></p>
<p><strong><span style="font-size:11pt;">The owner of the personal data processed at COR-Lab is &quot;COR-SOFTWARE&quot; LLC</span></strong><span style="font-size:11pt;">&nbsp;(a company established and existing under the laws of Ukraine, registered at the address: Ukraine, 03057 Kyiv, Antona Tsedik St., 12. Identification code of the legal entity in the Unified State Register of Enterprises and Organizations of Ukraine - 44812910).</span></p>
<p><br></p>
<p><span style="font-size:11pt;">Personal data will be included in the &quot;COR-SOFTWARE&quot; Personal Data Database (hereinafter - the Personal Data Database).&nbsp;</span></p>
<p><br></p>
<p><strong><span style="font-size:11pt;">Location of personal data: data center of &quot;COR-SOFTWARE&quot; LLC.</span></strong><span style="font-size:11pt;">&nbsp;</span></p>
<p><span style="font-size:11pt;">The procedure for collecting personal data assumes that COR-Lab stores information that was entered into the system during interaction with COR-Lab, for example, through the user account, when you enter data yourself or perform certain actions (appointment, order, etc.) , when entering information by a doctor or other specialist who provides you with medical assistance or medical services, etc. It is also possible to collect personal data when contacting our call center.&nbsp;</span></p>
<p><span style="font-size:11pt;">You have the option to allow us to import data from third-party services and/or devices, such as Apple and Google (&quot;Third Parties&quot;).</span></p>
<p><span style="font-size:11pt;">You decide for yourself which data COR-Lab will receive from third parties. For this, you need to provide the appropriate permissions in your device (applications). Permission to receive information from third parties can help you improve your use of COR-Lab.&nbsp;</span></p>
<p><span style="font-size:11pt;">You can refuse to provide information about yourself or contact us with a request to delete already entered information.&nbsp;</span></p>
<p><span style="font-size:11pt;">The deletion of already existing information is carried out in the following cases:</span></p>
<p><span style="font-size:11pt;">1) expiration of the data storage period determined by the consent of the subject of personal data to the processing of this data or by law;&nbsp;</span></p>
<p><span style="font-size:11pt;">2) termination of the legal relationship between the subject of personal data and the owner or administrator, unless otherwise provided by law;&nbsp;</span></p>
<p><span style="font-size:11pt;">3) issuance of a corresponding order by the Commissioner or officials of the Secretariat of the Commissioner designated by him;</span></p>
<p><span style="font-size:11pt;">4) entry into legal force of a court decision on the removal or destruction of personal data.</span></p>
<p><span style="font-size:11pt;">We do not have the right or the technical ability to delete information entered into the registers of the central database of the electronic health care system (ie, everything related to declarations on the choice of doctor, electronic referrals, electronic prescriptions entered into the registers and medical services and drugs that are paid from the state budget). To change such information, you need to contact the National Health Service of Ukraine, which is the owner of the information stored in the registers of the central database.&nbsp;</span></p>
<p><br></p>
<p style="text-align: center;"><strong><span style="font-size:11pt;">Personal data may include:</span></strong></p>
<p><br></p>
<p><strong><span style="font-size:11pt;">1) Data required for registration and account support:&nbsp;</span></strong></p>
<p><span style="font-size:11pt;">● Full Name;&nbsp;</span></p>
<p><span style="font-size:11pt;">● Gender;&nbsp;</span></p>
<p><span style="font-size:11pt;">● Date of birth;&nbsp;</span></p>
<p><span style="font-size:11pt;">● Passport data;&nbsp;</span></p>
<p><span style="font-size:11pt;">● Registration number of the taxpayer&apos;s account (if available);</span></p>
<p><span style="font-size:11pt;">● The address of the registered place of residence and/or place of residence;&nbsp;</span></p>
<p><span style="font-size:11pt;">● Means of communication, such as email address and telephone number;&nbsp;</span></p>
<p><span style="font-size:11pt;">● IP addresses;&nbsp;</span></p>
<p><span style="font-size:11pt;">● Other data entered into COR-Lab or information about actions performed in the system; information about the terms of the insurance policy, if any, including the period of validity of the contract, the sum insured and the insurance payment;&nbsp;</span></p>
<p><span style="font-size:11pt;">● Data about related persons (in case specified by users or are guardians or custodians), including full name, gender, date of birth, and other information in the document certifying the relationship;</span></p>
<p><span style="font-size:11pt;">● Preferential categories&nbsp;</span></p>
<p><span style="font-size:11pt;">● Mobile device location and other unique device identifiers.&nbsp;</span></p>
<p><span style="font-size:11pt;">● Photo.&nbsp;</span></p>
<p><br></p>
<p><br></p>
<p><strong><span style="font-size:11pt;">2) Data necessary for maintaining an electronic medical record,&nbsp;</span></strong><span style="font-size:11pt;">which are sensitive data (i.e. data related to your health):&nbsp;</span></p>
<p><span style="font-size:11pt;">● information about the family doctor, the history of making declarations with family doctors, appointment with the doctor, the very fact of contacting the doctor, planned visits and related information;&nbsp;</span></p>
<p><span style="font-size:11pt;">● all medical information containing not only data on the state of health, but also on the medical history, proposed research and treatment measures, referrals, results of medical examination and provision of medical services, prognosis of the possible development of the disease, presence of risk to life and health, relevant medical documents related to health history of searches and bookings/orders of medicines:&nbsp;</span></p>
<p><span style="font-size:11pt;">● photo/video images that you upload to COR-Lab (including for obtaining online consultations of doctors).</span></p>
<p><br></p>
<p><strong><span style="font-size:11pt;">3) Data on vaccination against Covid-19:</span></strong></p>
<p><span style="font-size:11pt;">COR-Lab stores data related to your health. Such data include information on vaccination. The storage of such information is regulated by the following legal acts:&nbsp;</span></p>
<p><span style="font-size:11pt;">Law of Ukraine &quot;On Protection of Personal Data&quot;;&nbsp;</span></p>
<p><span style="font-size:11pt;">The procedure for the functioning of the electronic health care system, approved by the resolution of the Cabinet of Ministers of Ukraine dated April 25, 2018 No. 411;&nbsp;</span></p>
<p><span style="font-size:11pt;">The procedure for maintaining the Register of medical records, referral records and prescriptions in the electronic health care system, approved by the Order of the Ministry of Health of Ukraine of February 28, 2020 No. 587.&nbsp;</span></p>
<p style="text-align: center;"><strong><span style="font-size:11pt;">COR-Lab stores information about:</span></strong></p>
<p><span style="font-size:11pt;">● Date of vaccination;&nbsp;</span></p>
<p><span style="font-size:11pt;">● Serial number of the dose;&nbsp;</span></p>
<p><span style="font-size:11pt;">● Manufacturer and trade name of the vaccine; vaccine serial number;&nbsp;</span></p>
<p><span style="font-size:11pt;">● The name of the person who made the vaccination record;&nbsp;</span></p>
<p><span style="font-size:11pt;">● Type of procedure, dosage, the way of vaccine administration;</span></p>
<p><span style="font-size:11pt;">● Localization of vaccine administration;&nbsp;</span></p>
<p><span style="font-size:11pt;">● Vaccine code and description;&nbsp;</span></p>
<p><span style="font-size:11pt;">● The number of doses according to the protocol; vaccination stage;&nbsp;</span></p>
<p><span style="font-size:11pt;">● Author and description of the vaccination protocol.&nbsp;</span></p>
<p><br></p>
<p><span style="font-size:11pt;">The method of obtaining data: after giving consent to the processing of personal data, the doctor (medical officer) enters information about your vaccination into the registers of the electronic health care system, in accordance with the Procedure for maintaining the Register of medical records, referral records and prescriptions in the electronic health care system approved by Order No. 587 of the Ministry of Health of Ukraine dated February 28, 2020.&nbsp;</span></p>
<p><br></p>
<p><span style="font-size:11pt;">Vaccination information is stored at COR-Lab for the purpose of maintaining your electronic medical record, which can only be accessed by you or doctors (medical professionals) during the provision of medical care or medical services to you. Vaccination information is not used in any other functionality of COR-Lab. The storage of information is carried out in accordance with the Procedure for the functioning of the electronic health care system, approved by the Resolution of the Cabinet of Ministers of Ukraine dated April 25, 2018 No. 411, based on your consent.</span></p>
<p><br></p>
<p><span style="font-size:11pt;">Information about your vaccination against Covid-19 is not transferred to third parties. Security measures: All vaccination data at COR-Lab is encrypted and/or anonymized. You can familiarize yourself with other security measures in the &quot;Protection of personal data&quot; section of this Notice.&nbsp;</span></p>
<p><span style="font-size:11pt;">Access to the camera and any files for purposes related to the fight against COVID-19 is not used. COR-Lab only stores information about vaccination against COVID-19, but does not process this data in any other way and does not issue official conclusions (certificates).&nbsp;</span></p>
<p><br></p>
<p><span style="font-size:11pt;">You can get a vaccination memo note from COR-Lab. Please note that such a note is not an official document or certificate and is intended for your use for personal purposes (for example, if you want to have information about the vaccination that was entered into the electronic health care system in paper form). We do not transfer this form to any third parties or use it for any purpose other than those specified in the &quot;Covid-19 Vaccination Data&quot; section of this Notice.</span></p>
<p><strong><span style="font-size:11pt;">4) Data received from third parties&nbsp;</span></strong></p>
<p><span style="font-size:11pt;">Depending on the permissions you have given and the amount of information available in the relevant application (Apple or Google), COR-Lab may process the following information received from third parties: weight, height, waist circumference, body fat percentage, temperature, blood pressure, heart rate, resting heart rate, blood glucose level, body mass index, respiratory rate, lung volume, blood oxygen saturation (oxygenation), number of steps, minutes of activity (activity), calories burned, sleep, calories consumed, hydration.&nbsp;</span></p>
<p><br></p>
<p><span style="font-size:11pt;">Use of the photo is at your discretion. You decide whether to upload a photo or not.&nbsp;</span></p>
<p><span style="font-size:11pt;">If you choose to do so, your photo will be stored in the COR-Lab account management system. We guarantee that this is the only way we will use your image.&nbsp;</span></p>
<p><br></p>
<p><span style="font-size:11pt;">Accessing certain data on your device is necessary for certain COR-Lab functionality, including access to:&nbsp;</span></p>
<p><br></p>
<p><span style="font-size:11pt;">● locations to show you the nearest pharmacies;&nbsp;</span></p>
<p><span style="font-size:11pt;">● camera and microphone for making a video call with your doctor during an online consultation;&nbsp;</span></p>
<p><span style="font-size:11pt;">● bluetooth, if you use a wireless headset during an online consultation with a doctor;&nbsp;</span></p>
<p><span style="font-size:11pt;">● the internal storage of your device/camera in case of uploading files or photos to the electronic medical record maintained by COR-Lab;</span></p>
<p><span style="font-size:11pt;">● calendar to add doctor&apos;s appointment information to it.&nbsp;</span></p>
<p><br></p>
<p><span style="font-size:11pt;">You can prevent COR-Lab&rsquo;s access to such data in the settings of your device. If you do not grant permission in such cases, we will not be able to provide the corresponding functionality.</span></p>
<p><br></p>
<p style="text-align: center;"><strong><span style="font-size:11pt;">The protection of personal data is a priority for COR-Lab.</span></strong></p>
<p><span style="font-size:11pt;">In accordance with the requirements established by the Law of Ukraine &quot;On the Protection of Information in Information and Communication Systems&quot;, COR-Lab has built a comprehensive information protection system (CIPS), which has successfully passed an audit for compliance with the requirements of the documents of the technical information protection system in Ukraine, which is confirmed by a certificate of compliance of the comprehensive of the information protection system, registered in the administration of the State Service for Special Communications and Information Protection of Ukraine on October 7, 2022 under No. 76-B.</span></p>
<p><span style="font-size:11pt;">All personal data entered into the system are encrypted or depersonalized (that is, information that allows direct or indirect identification of a person is removed from them) in cases where such depersonalization does not lead to the impossibility of providing services to users.&nbsp;</span></p>
<p><br></p>
<p><span style="font-size:11pt;">Personal data from the Personal Data Base are processed in COR-Lab automatically, while their storage is carried out in an encrypted or depersonalized form, which excludes the possibility of identifying a person. Our employees get access to your data (any) only in exceptional cases, for example, if you yourself contacted our call center, on the basis of a court decision, etc. Such employees must provide a written commitment not to disclose personal data that was entrusted to them or that became known to them in connection with the performance of professional or official duties. Each such fact of access to personal data is recorded and stored.&nbsp;</span></p>
<p><br></p>
<p style="text-align: center;"><strong><span style="font-size:11pt;">Personal data may be transferred to:</span></strong></p>
<p><span style="font-size:11pt;">● Health care institutions and natural persons-entrepreneurs (doctors) who have received a license to carry out economic activities for medical practice (i.e. have a license from the Ministry of Health) exclusively for you to receive medical assistance or medical services;&nbsp;</span></p>
<p><span style="font-size:11pt;">● The body, authorized under with the current legislation of Ukraine to maintain the Register of Patients, the Register of Medical Records, Records of Referrals and Prescriptions, the Register of Medical Opinions, etc. (if the information must be transferred in accordance with the legislation), as well as to the owners of other registers created in accordance with the legislation of Ukraine, if the information about you must be transferred there and corresponds to its purpose (it can be the Unified state database of medical examinations of certain categories of persons in the health care system, if you undergo a professional medical examination, etc.), in particular in accordance with the resolution of the Cabinet of Ministers of Ukraine No. 411 of April 25, 2018 &quot;Some issues of the electronic health care system, order of the Ministry of Health of Ukraine No. 587 of February 28, 2020 &quot;Some issues of maintaining the Register of medical records, referral records and prescriptions in the electronic health care system&quot;, order Ministry of Health No. 2136 dated September 18, 2020 &quot;Some issues of maintaining the Register of medical opinions in the electronic health care system;&nbsp;</span></p>
<p><span style="font-size:11pt;">● To other persons identified by you personally, in the case of contacting such persons (i.e. registration using COR-Lab, ordering certain services using COR-Lab, concluding contracts using COR-Lab, performing other actions using COR-Lab, etc.).</span></p>
<p><br></p>
<p><span style="font-size:11pt;">At the same time, please note that the transfer is possible only in connection with your active actions (that is, your request always precedes the transfer of data); to preserve the integrity and completeness of the data entered in COR-Lab, to ensure the availability of data for users, to fulfill obligations to the subjects of personal data (who have given consent to the processing of personal data in COR-Lab), as well as to exercise the rights of the subject of personal data provided for in clauses 3 and 7 of the second part of Article 8 of the Law of Ukraine &quot;On the Protection of Personal Data&quot;, LLC &quot;COR-SOFTWARE UA&quot; (as the owner of personal data that was previously entered in COR-Lab), after you have given your consent to processing personal data, transfers for processing your personal data, which were previously entered in COR-Lab, to the new owner of COR-Lab - &quot;COR-SOFTWARE&quot; LLC, which is the owner of personal data (in accordance with the terms of consent to the processing of personal data set forth in this messages).</span></p>
<p><br></p>
<p><span style="font-size:11pt;">Such transfer is carried out by COR-Lab software directly in the system and does not involve the transfer of data to third parties or the involvement of third parties.</span></p>
<p><br></p>
<p><span style="font-size:11pt;">● in the issues of national security, economic well-being and human rights, in particular, but not exclusively, at the justified requests of government bodies that have the right to request and receive such data and information.&nbsp;</span></p>
<p><br></p>
<p><span style="font-size:11pt;">In the case of your purchase of goods (including medical products or medicines) using an electronic prescription, we do not transfer information by which you can be specifically identified.</span></p>
<p><span style="font-size:11pt;">The pharmacy is provided only with the number of the electronic prescription, which is used for further interaction.</span></p>
<p><span style="font-size:11pt;">In the case of your purchase of goods (including medical products or medicines) using an electronic prescription, we do not transfer information by which you can be specifically identified. The pharmacy is provided only with the number of the electronic prescription, which is used for further interaction. The transfer of such information is carried out in accordance with the order of the Ministry of Health of Ukraine No. 360 of July 19, 2005 &quot;On the approval of the Rules for issuing prescriptions for medicinal products and medical products, the Procedure for dispensing medicinal products and medical products from pharmacies and their structural divisions, Instructions on the procedure storage, accounting and destruction of prescription forms&quot;.&nbsp;</span></p>
<p><span style="font-size:11pt;">Pharmacies can receive general information about active substances, pharmacological effect, type of biological activity, pharmacotherapeutic principle, categories of medicines you need/interest in, etc. We do not transfer your surname, first name, patronymic, passport data, address information or other personal data to pharmacies.</span></p>
<p><span style="font-size:11pt;">In order to carry out mailings (both authorization and informational), your telephone subscriber number can be transferred to persons with whom we have signed relevant contracts and who are included in the register of providers of electronic communication networks and services. Your telephone subscriber number will not be compared with any other databases, regardless of their owner, which excludes any identification of your person.&nbsp;</span></p>
<p><span style="font-size:11pt;">By using the phone number, you may be provided with information (including advertising) in accordance with the conditions and purpose specified in this Notice.&nbsp;</span></p>
<p><span style="font-size:11pt;">You also give your consent to &quot;COR-SOFTWARE&quot; LLC to receive from the operator of electronic communication services that provides you with mobile communication services and processes data related to the provision of such services, information about telecommunication services and your location, necessary for the following purpose of data processing: maintaining a user profile, providing recommendations and information.&nbsp;</span></p>
<p><span style="font-size:11pt;">At the same time, you give consent to the relevant operator of electronic communication services to process and transfer to us information about electronic communication services and your location.&nbsp;</span></p>
<p><span style="font-size:11pt;">Transfer (distribution) of personal data in the cases specified in this Notice is carried out without obtaining additional consent and a separate notification of the subject of personal data.&nbsp;</span></p>
<p><span style="font-size:11pt;">The purpose of collecting personal data is to provide high-quality and timely services for the use of COR-Lab, to ensure continuous, complete and accurate functioning of the system for your convenience. This applies not only to the system as a whole, but also to individual services that you can choose:</span></p>
<p><span style="font-size:11pt;">● an appointment with a doctor;&nbsp;</span></p>
<p><span style="font-size:11pt;">● maintaining an electronic medical record;&nbsp;</span></p>
<p><span style="font-size:11pt;">conducting online consultations;&nbsp;</span></p>
<p><span style="font-size:11pt;">● medication booking;&nbsp;</span></p>
<p><span style="font-size:11pt;">● execution of insurance contracts; information storage;</span></p>
<p><span style="font-size:11pt;">● receiving recommendations or information, etc.&nbsp;</span></p>
<p><br></p>
<p><span style="font-size:11pt;">Thanks to COR-Lab, the process of providing you with medical assistance or medical services, remote medical monitoring, providing online consultations, establishing a medical diagnosis, providing care or treatment can be organized.&nbsp;</span></p>
<p><span style="font-size:11pt;">The information stored in COR-Lab can be used to provide you with reference recommendations, form individual information reports, provide the possibility of data analysis, etc. Such information is provided to you for reference and general information.</span></p>
<p><span style="font-size:11pt;">By the assistance of COR-Lab, the process of providing you with medical care or medical services, remote medical monitoring, providing online consultations, establishing a medical diagnosis, providing care or treatment can be organized. The information stored in COR-Lab can be used to provide you with reference recommendations, form individual information reports, provide the possibility of data analysis, etc. Such information is provided to you for reference and general information.</span></p>
<p><span style="font-size:11pt;">We can contact you using the means of communication that you have provided to confirm an appointment with a doctor, to clarify or provide information, to check the correctness of data.</span></p>
<p><span style="font-size:11pt;">From time to time we will send you information messages from us, i.e. calls, SMS, e-mail, messages directly to COR-Lab (including push messages), Viber, Telegram and other messengers, etc. This is necessary for registration, authorization and confirmation of certain actions in COR-Lab. You cannot refuse such actions, as it is necessary for the normal functioning of the site and the application.&nbsp;</span></p>
<p><span style="font-size:11pt;">We may also send you information that you may need or be interested in in the field of medicine and pharmacology/pharmacy.&nbsp;</span></p>
<p><span style="font-size:11pt;">You can opt out of receiving other informational messages by contacting our team in one of the following ways:&nbsp;</span></p>
<ol>
<li style="list-style-type:decimal;font-size:11pt;">
    <p><span style="font-size:11pt;">using the COR-Lab feedback form;&nbsp;</span></p>
</li>
<li style="list-style-type:decimal;font-size:11pt;">
    <p><span style="font-size:11pt;">by sending an email to&nbsp;</span><a href="mailto:info@cor-medical.com"><u><span style="color:#0563c1;font-size:11pt;">info@cor-medical.com</span></u></a><span style="font-size:11pt;">;&nbsp;</span></p>
</li>
<li style="list-style-type:decimal;font-size:11pt;">
    <p><span style="font-size:11pt;">by sending a letter to our address: 12, Anton Tsedik Str., 03057 Kyiv,</span></p>
</li>
</ol>
<p><span style="font-size:11pt;">Ukraine.</span></p>
<p><strong><span style="font-size:11pt;">Adding &quot;related persons&quot;</span></strong><span style="font-size:11pt;">&nbsp;allows you to delegate appointments, cancellations, etc. to relatives or other trusted persons. Such actions require your confirmation. You decide for yourself whether you want to establish a connection with an individual that allows you to view certain categories of information about you and/or access information about such an individual.&nbsp;</span></p>
<p><span style="font-size:11pt;">If you and your related person have a corresponding desire, the doctor during the appointment can verify the relationship established by you (by checking documents, and in the absence of documented reasons - after receiving proper confirmation), which allows:&nbsp;</span></p>
<p><span style="font-size:11pt;">● create, review, cancel and otherwise manage an appointment (only the fact of an appointment becomes available) of a husband or wife (or a person with whom you live in the same family without an official registration of marriage), provided that both persons give their consent;&nbsp;</span></p>
<p><span style="font-size:11pt;">● create, view, cancel and otherwise manage an appointment (only the fact of an appointment becomes available) of a guardian or ward.</span></p>
<p><span style="font-size:11pt;">In the case of representation (legal representation by parents of children, guardianship or guardianship), by providing the doctor with official documents for verification, certifying the fact of such representation and confirming the presence of authority, you can:&nbsp;</span></p>
<p><span style="font-size:11pt;">● view all information (including medical) and perform all necessary actions in your minor child&apos;s account;&nbsp;</span></p>
<p><span style="font-size:11pt;">● view all information (including medical) and perform all necessary actions in the ward&apos;s account.</span></p>
<p><strong><span style="font-size:11pt;">Transfer of information to insurance companies</span></strong><span style="font-size:11pt;">&nbsp;is carried out only if there are sufficient grounds.&nbsp;</span></p>
<p><span style="font-size:11pt;">We cooperate with a number of insurance companies and their official partners in several areas.</span></p>
<p><span style="font-size:11pt;">If you wish to issue an insurance contract with the help of COR-Lab, such relations are regulated by contracts, which you can familiarize yourself with at the link <a href="https://cor-medical.ua/platform"><u><span style="font-size:12pt;">https://cor-medical.ua/platform</span></u></a> or in the mobile application.&nbsp;</span></p>
<p><span style="font-size:11pt;">A special type of cooperation is the transfer of depersonalized information (including established diagnoses and prescribed medications, etc.) for the settlement of insurance cases and the realization of their rights and obligations by the insured person and the insurer under previously concluded contracts (with insurance companies).&nbsp;</span></p>
<p style="text-align: center;"><strong><span style="font-size:11pt;">We draw your attention to the following:</span></strong></p>
<p><span style="font-size:11pt;">1. Transfer of depersonalized information (i.e. those that do not include full name, personal identification number, place of registration/residence, phone number, e-mail, but there is information about established diagnoses and prescribed medications, etc.) for settlement of insurance cases and exercise of rights by the parties and obligations under previously concluded contracts with insurance companies is possible only if such a transfer was expressly provided for in your insurance contract;&nbsp;</span></p>
<p><span style="font-size:11pt;">2. The transfer of the specified information is possible only after you provide your family doctor with the number of the insurance contract and the date of birth of the insured person for entering into the system. Providing the specified information to the family doctor is considered a conclusive action (that is, an action of a person expressing his will to establish a legal relationship) and confirms consent to the use of COR-Lab to transfer depersonalized information (including established diagnoses and prescribed drugs, etc.) for processing by the insurance company ( in the electronic systems used by it) for the purpose of using such data by the insurer to fulfill obligations under the concluded insurance contract, as well as for the parties to exercise their rights and obligations under such a contract;&nbsp;</span></p>
<p><span style="font-size:11pt;">3. In compliance with the requirements of the Law of Ukraine &quot;On the Protection of Information in Information and Telecommunication Systems&quot;, we sign a contract with the owner of the electronic system to which depersonalized information is transferred.&nbsp;</span></p>
<p><span style="font-size:11pt;">4. In no case will we transfer your passport data, RNOCPP, information about your place of residence, etc. The only identifying information (which can only be used by the insurance company with which you have already concluded a contract) is the number of the insurance contract provided by you and the date of birth of the insured person;</span></p>
<p><span style="font-size:11pt;">5. The amount of information to be transferred was previously determined by you when concluding an insurance contract with an insurance company;&nbsp;</span></p>
<p><span style="font-size:11pt;">6. We do not take part in the conclusion of such insurance contracts and do not deal with the settlement of insurance cases. If you have any questions about such an agreement, you should call the phone number specified in your insurance contract (or policy).</span></p>
<p style="text-align: center;"><strong><span style="font-size:11pt;">Use of cookies</span></strong></p>
<p><span style="font-size:11pt;">For your convenience, COR-Lab uses cookies. Cookies are small text files that are stored when you visit almost all web pages. This data cannot be linked to individual users as it is collected anonymously.&nbsp;</span></p>
<p><span style="font-size:11pt;">We use the following types of cookies:</span></p>
<p><strong><span style="font-size:11pt;">Functional</span></strong><span style="font-size:11pt;">: such cookies are necessary for more convenient use of COR-Lab, setting of privacy settings, secure search, filling out forms to facilitate registration of new services and protection of your data.&nbsp;</span></p>
<p><strong><span style="font-size:11pt;">Analytical:</span></strong><span style="font-size:11pt;">&nbsp;with the help of such cookies, we collect statistical information about the use of COR-Lab, improve the user environment, track the use of online services or tools, the number of visits and traffic sources.&nbsp;</span></p>
<p><span style="font-size:11pt;">Also, to improve your experience of using web services and other sites, for the purpose of targeting and analytics of advertising campaigns, cookies of the &quot;3rd party&quot;.</span></p>
<p><span style="font-size:11pt;">After you have given your prior consent in the application, we can use advertising identifiers &ndash; unique variable identifiers for advertising (provided by Google Play, AppStore and Huawei Ads publisher service) for the above purpose. Such identifier is not associated with any personally identifiable information or persistent device identifiers (such as SSAID, MAC address, IMEI, etc.)</span></p>
<p><span style="font-size:11pt;">All information collected by cookies (advertising identifiers) is general by audience, i.e. the data is not personalized and 100% anonymous.&nbsp;</span></p>
<p><span style="font-size:11pt;">It is also possible to use COR-Lab without using cookies. You can turn them off at any time on your device or adjust them according to your needs. The advertising identifier can be reset by you at any time in the system settings of your device.</span></p>
<p><strong><span style="font-size:11pt;">Under the second part of Article 8 of the Law of Ukraine &quot;On the Protection of Personal Data&quot;, the subject of personal data has the right:&nbsp;</span></strong></p>
<p><span style="font-size:11pt;">● to know about the sources of collection, the location of their personal data, the purpose of their processing, the location or place of residence (residence) of the owner or manager of personal data or to give an appropriate order to obtain this information to persons authorized by him, except for cases established by law;&nbsp;</span></p>
<p><span style="font-size:11pt;">● receive information about the conditions for providing access to personal data, in particular information about third parties to whom your personal data is transferred;&nbsp;</span></p>
<p><span style="font-size:11pt;">● to access your personal data;&nbsp;</span></p>
<p><span style="font-size:11pt;">● to receive no later than thirty calendar days (30) from the date of receipt of the request, except in cases provided by law, an answer about whether your personal data is being processed, as well as to receive the content of such personal data;&nbsp;</span></p>
<p><span style="font-size:11pt;">● submit a reasoned demand to the owner of personal data with an objection to the processing of his personal data;&nbsp;</span></p>
<p><span style="font-size:11pt;">● Reasonably demand the change or deletion of your personal data by any owner and administrator of personal data, if these data are processed illegally or are unreliable;&nbsp;</span></p>
<p><span style="font-size:11pt;">● to have protection of your personal data from illegal processing and accidental loss, destruction, damage due to intentional concealment, failure to provide or untimely provision of data, as well as protection from providing information that is unreliable or disgraces the honor, dignity and business reputation of a natural person;</span></p>
<p><span style="font-size:11pt;">● file complaints about the processing of your personal data with the Verkhovna Rada Commissioner for Human Rights or with the court;&nbsp;</span></p>
<p><span style="font-size:11pt;">● apply legal remedies in case of violation of the legislation on the protection of personal data;&nbsp;</span></p>
<p><span style="font-size:11pt;">● enter a reservation regarding the limitation of the right to process your personal data when giving consent;&nbsp;</span></p>
<p><span style="font-size:11pt;">● withdraw consent to the processing of personal data; know the mechanism of automatic processing of personal data;&nbsp;</span></p>
<p><span style="font-size:11pt;">● to be protected against an automated decision that has legal consequences for him.</span></p>
<p><strong><span style="font-size:11pt;">To exercise your rights, you can contact us:&nbsp;</span></strong></p>
<p><span style="font-size:11pt;">● using the COR-Lab feedback form;&nbsp;</span></p>
<p><span style="font-size:11pt;">● by sending an email to info@cor-medical.com;&nbsp;</span></p>
<p><span style="font-size:11pt;">● by sending a letter to our address: 12, Anton Tsedik Str., 03057 Kyiv, Ukraine.</span></p>
<p><strong><span style="font-size:11pt;">The time period of processing and deletion of personal data</span></strong><span style="font-size:11pt;">&nbsp;is determined in accordance with the legislation and your consent to the processing of personal data, the terms of which are set out in this notice.&nbsp;</span></p>
<p><span style="font-size:11pt;">Personal data is stored at COR-Lab for as long as you use the system and for 5 years after the last access to such data by you or your doctor. In the event that personal data is part of an electronic medical record created in accordance with current legislation, the term of storage of such electronic medical record is 5 years, unless another term of its storage is specified by law.&nbsp;</span></p>
<p><span style="font-size:11pt;">If we receive information, which is confirmed by relevant evidence, about the introduction of inaccurate information into the system, violation of the COR-Lab User Agreement or this message, use of third-party communication means for registration or registration of third parties without sufficient authority, &quot;COR-SOFTWARE&quot; LLC reserves the right, without prior notice, to block the account in respect of which such information was found, for the duration of the inspection.</span></p>
<p><span style="font-size:11pt;">According to the results of the check, in case of establishing the fact of entering inaccurate information into the system, violation when using COR-Lab User Agreement or this notice, use for registration of means of communication belonging to third parties or registration of third parties without sufficient authority (or impossibility to refute such facts), a decision may be made to delete inaccurate information or to delete the account in respect of which such facts were discovered, in general without the possibility of further recovery (in the case of entering inaccurate data regarding means of communication (which are used as one of the identifiers in Cor- Medical), full name, or if the amount of inaccurate information left after deletion does not allow creating a user account), about which the person who created such an account can be notified if we have information about his means of communication.</span></p>
<p><span style="font-size:11pt;">Ban or deleting an account in COR-Lab does not affect the information entered in the registers of the central database of the electronic health care system (that is, everything related to declarations on the choice of a doctor, electronic referrals, electronic prescriptions entered in the registers and other medical services and medicines paid for from the state budget, information about which is stored in the relevant state registers).</span></p>
<p><span style="font-size:11pt;">If you have found inaccurate information about yourself in the system, contact the administration of the health care facility (a natural person-entrepreneur who has a license to conduct economic activity in the field of medical practice), whose employee entered such information. You can also submit an application to remove inaccurate information from COR-Lab.&nbsp;</span></p>
<p><span style="font-size:11pt;">If COR-Lab does not have the information that you think should be stored in the system, you should contact your doctor or a health care institution (a natural person-entrepreneur who has a license to conduct economic activity from medical practice), whose employee is not the following information was entered. We cannot introduce it ourselves. You can read about other rights regarding your personal data in another part of this Notice, which deals with the rights of users as subjects of personal data.&nbsp;</span></p>
<p><span style="font-size:11pt;">The information processed at COR-Lab is not collected by us independently, but is entered by you, your doctor or another person who provides you with medical care or medical services.&nbsp;</span></p>
<p><span style="font-size:11pt;">Also, information about you may be received (transmitted) in the cases expressly provided for in this consent. At the same time, the procedure for collecting personal data remains unchanged, it is information entered into COR-Lab by you, your doctor or another person who provides you with medical assistance or medical services and/or defined in this notice.&nbsp;</span></p>
<p style="text-align: center;"><strong><span style="font-size:11pt;">Final provisions</span></strong></p>
<p><span style="font-size:11pt;">The functionality of COR-Lab and services using the system can be provided in full only if we previously receive your consent to the processing of personal data.</span></p>
<p><span style="font-size:11pt;">You can learn with COR-Lab capabilities that are available without authorization (giving consent to the processing of personal data) in the User Agreement, which is available on the website or in the COR-Lab mobile application.</span></p>
<p><span style="font-size:11pt;">Amendments and additions to this notice on the processing of personal data are made by issuing their new version in electronic form, which are published in COR-Lab.&nbsp;</span></p>
<p><span style="font-size:11pt;">The date of entry into force of the new edition of the notice on the processing of personal data is the date of its publication in COR-Lab. We will definitely notify you of such changes by requesting a new consent to the processing of personal data (in accordance with the terms of the new version of the notice on the processing of personal data).</span></p>
<p><span style="font-size:11pt;">In case of rejection (disagreement) of such a new edition, we ask you to stop using COR-Lab and remind you that you have the right to demand the destruction of your personal data entered in COR-Lab in accordance with the procedure established by law.</span></p>
`,
    }
}
