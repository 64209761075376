export default class WidgetNameEnum {
  static get string() {
    return "string";
  }
  static get number() {
    return "number";
  }
  static get integer() {
    return "integer";
  }
  static get image() {
    return "image";
  }
  static get link() {
    return "image";
  }
  static get phone() {
    return "image";
  }
  static get video() {
    return "video";
  }
  static get url() {
    return "url";
  }
  static get date() {
    return "date";
  }

  static get time() {
    return "time";
  }
  static get datetime() {
    return "datetime";
  }
  static get lastMember() {
    return "last_member";
  }
  static get markdown() {
    return "markdown";
  }
  static get html() {
    return "html";
  }
  static get default() {
    return "default";
  }
}
