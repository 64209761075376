/**
 * external libs
 */
import React from 'react'
/**
 * components
 */
import SideBarItem from '../Item'
import styles from '../Item/index.module.scss'

export default function ChildrenItem({ items, activeFolder, disableFolders, openSideBar, setOpenChildren}){
    const onClickOnChild = () => {
        if(!openSideBar){
            setOpenChildren(false)
        }
    }

    return (
        <div className={`${styles.item__children} ${!openSideBar && styles.close}`}  onClick={onClickOnChild}>
            {items.map((item, index) => (
                <SideBarItem showTooltip={false} key={index} index={index} activeFolder={activeFolder} disableFolders={disableFolders} item={item} openSideBar={openSideBar}/>
            ))}
        </div>
    )
}
