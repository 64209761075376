import React, {useEffect, useRef, useState} from 'react'
import styles from './index.module.scss'

function validURL(str) {
    const pattern = new RegExp(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/
        ,'i'); // fragment locator
    return !!pattern.test(str);
}

export default function LinkEdit({ onLinkChange, setOpenChangeLink, url = "" }){
    const [currentUrl, setCurrentUrl] = useState(url)
    const [validUrl, setValidUrl] = useState(false)
    const inputRef = useRef();

    const onChange = (e) => {
        const url = e.target.value.trim();
        const isValidUrl = validURL(url);

        setValidUrl(isValidUrl)
        setCurrentUrl(url)
    }

    const closePopup = () => {
        const url = inputRef.current.value.trim();

        if(validUrl){
            onLinkChange(url)
            setOpenChangeLink(false)
        }
    }

    useEffect(() => {
        if(inputRef.current){
            inputRef.current.focus()
            inputRef.current.select()
        }
    }, [])

    return (
        <div className={styles.popup}>
            <input type="text"  value={currentUrl} onChange={onChange} ref={inputRef}/>
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" style={{cursor: "pointer"}} onClick={closePopup}>
                <rect width="30" height="30" rx="15" fill={validUrl ? "#E7FDE9" : "#D9D9D9"}/>
                <path d="M9 15.3195L12.5773 18.8968C12.6945 19.014 12.8844 19.014 13.0016 18.8968L21 10.8984" stroke={validUrl ? "#54CE60" : "#7B7B80"} stroke-width="2" stroke-linecap="round"/>
            </svg>
        </div>
    )
}
