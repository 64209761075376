export default function ukTranslate() {
    return {
        "Calendar": "日历",
        "Notifications": "通知",
        "Statistics": "统计数据",
        "Search": "搜索",
        "Create record": "创建记录",
        "Settings": "设置",
        "Security": "安全",
        "Folders": "文件夹",
        "Catalogs": "目录",
        "Widgets": "小组件",
        "Language": "语言",
        "Share":"分享",
        "Label":"標籤",
        "Unassigned": "缺席",
        "Features and tips": "功能和建议",
        "Q&A": "问题和答复",
        "User agreement": "用户协议",
        "Update the app": "重新启动应用程序",
        "Date": "日期",
        "Value": "数值",
        "Time": "时间",
        "All notifications": "所有通知",
        "Delete all data": "删除所有数据",
        "Default": "默认",
        "Diagnosis": "诊断",
        "Date of visits": "就诊日期",
        "Status": "地位",
        "Full name": "全名",
        "Gender and age": "性别和年龄",
        "Add account": "加帐户",
        "Log out": "登出",
        "Action": "行动",
        "y.o.": "岁",
        "Create result": "生成结果",
        "Create New Folder": "创建新文件夹",
        "Upload Catalog": "上传目录",
        "Create child folder": "创建子文件夹",
        "Copy folder id": "复制文件夹 ID",
        "Add field": "加新结果",
        "Edit": "编辑",
        "Edit Catalog": "编辑目录",
        "Icon": "图标",
        "Name": "名字",
        "Translation Warnings:": "翻译警告",
        "Copy to clipboard": "复制到剪贴板",
        "Ordinal": "序号",
        "Catalog": "目录",
        "Permit": "许可",
        "Included": "包括在内的",
        "Excluded": "不包括在内的",
        "Permitted": "许可",
        "Dimensions": "测定",
        "Included members": "包含的成分",
        "Catalog Name": "目录名称",
        "Dimensions for": "测定",
        "Explore": "研究",
        "Is required": "必需",
        "Is restricted": "受限",
        "Edit record": "编辑结果",
        "Go to widget": "转到小组件",
        "Is multiple": "多数",
        "Dimension": "测定",
        "Count": "计数",
        "Save": "保存",
        "Cancel": "取消",
        "Excluded members": "不包含的成分",
        "Row count": "行数",
        "Explore JSON": "研究 JSON",
        "Explore CSV": "研究 CSV",
        "Download catalog": "下载目录",
        "Member": "成分",
        "Result": "结果",
        "Actions": "动作",
        "Duplicate": "复制",
        "Copy Id": "复制 ID",
        "Delete": "删除",
        "Create New Widget": "创建新的小组件",
        "General": "一般",
        "Select data": "选择数据",
        "Select date": "选择日期",
        "Page not found": "找不到网页",
        "Include": "包括在内的",
        "Incarnation": "化身",
        "Parent Folder": "母文件夹",
        "Edit Folder": "编辑文件夹",
        "Edit Widget": "编辑小组件",
        "Exclude": "不包括在内的",
        "Members": "成分",
        "Translation": "翻译",
        "Will come soon": "即将到来",
        "Are you sure you want to delete this catalog?": "你确定要删除这个目录吗？",
        "Add glass QR code": "加载玻片二维码",
        "Create glass QR code": "创建载玻片二维码",
        "Yes": "是",
        "No": "否",
        "Data saved successfully!": "数据保存成功！",
        "Permitted members": "许可成分",
        "Failed to save data.": "保存数据失败",
        "Translations": "翻译",
        "Copied": "已複製",
        "Scan QR": "掃描二維碼",
        "Relation": "關係”",
        "Validity period": "有效期限",
        "Share link": "分享連結",
        "Forever": "永遠",
        "Year": "年",
        "Month": "月",
        "Week": "週",
        "Can view": "可以查看",
        "Can edit": "可以編輯",
        "Can reshare": "可以轉寄",
        "Share this medical card": "分享這張醫療卡",
        "Are you sure you want to delete this result?": "你确定要删除此结果吗？",
        "Are you sure you want to delete this record?": "你确定要删除此记录吗？",
        "Are you sure you want to delete this medical card?": "你确定要删除此病历卡吗？",
        "You successfully removed medical card": "病历卡已成功删除",
        "The medical record you are trying to delete does not exist!": "你尝试删除的病历卡不存在",
        "There are no records in this folder yet.": "在这个文件夹中还没有记录。",
        "Fill in required fields!": "请填写所有必填字段",
        "Policy Pages": "政策页",
        "User Agreement": "用户协议",
        "Personal info": "个人信息"
    }
}
