import React, {useState} from 'react';
import styles from "../../index.module.scss";
import FileItem from "../../../File";
import {Tooltip} from "antd";
import prettyBytes from "pretty-bytes";
import {useTranslation} from "react-i18next";

export default function EditebleItem({
                                         extensions,
                                         field,
                                         file,
                                         canBeLink,
                                         originalWidget,
                                         showDescription,
                                         result,
                                         deleteFiles,
                                         index
                                     }) {
    const {i18n} = useTranslation()
    const [fileSize, setFileSize] = useState(0);

    return (
        <tr>
            <td style={{verticalAlign: "middle", padding: "0 10px"}}>
                <div className={styles.files}>
                    <FileItem extensions={extensions} field={field} file={file} canBeLink={canBeLink}
                              result={result} showDescription={showDescription} widget={originalWidget}
                              tooltipShow={false}
                              getSize={setFileSize}
                    />
                </div>
            </td>
            <td style={{verticalAlign: "middle", padding: file?.name ? "10px" : "10px 0"}}>
                <Tooltip placement="topLeft" title={file?.url}>
                    <div>
                        {file?.name}
                    </div>
                </Tooltip>
            </td>
            <td style={{verticalAlign: "middle", padding: !!fileSize ? "10px" : "10px 0", whiteSpace: "nowrap"}}>
                {!!fileSize && prettyBytes(fileSize, {locale: i18n.language})}
            </td>
            <td style={{verticalAlign: "middle", padding: "0 10px"}}>
                <img onClick={() => deleteFiles(index)} className={styles.files__icon}
                     src="/image/icon/trash.svg" alt="trash"/>
            </td>
        </tr>
    )
}
