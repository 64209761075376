/**
 * external libs
 */
import React, {useMemo, useState} from "react";
/**
 * styles
 */
import styles from './index.module.scss'
import SearchLineSearch from "../SearchLine/components/Search";
import GetIcon from "../../../GetIcon";
import generateIconUrl from "../../../../utils/generateIconUrl";


export default function ColumnsMember({checkedLine, selectedMembers, columnIndex, addNewMembersRows, removeNewMembersRows, column, openColumns}) {
    const [isSearchReady, setIsSearchReady] = useState(true);
    const [searchValue, setSearchValue] = useState("");
    const [isSearch, setIsSearch] = useState(false);
    const isMultiply = useMemo(() => {
        return column?.columnOptions?.is_multiple
    }, [column?.columnOptions?.is_multiple])

    const clickColumns = (openColumns) => {
        if(isMultiply){
            return checkedLine(columnIndex, column)
        }

        if (openColumns) {
            return removeNewMembersRows(columnIndex, column.is_leaf)
        }

        return addNewMembersRows(columnIndex, column.is_leaf)
    }

    const onSearch = async (searchValue) => {
        setIsSearchReady(false)
        await addNewMembersRows(columnIndex, column.is_leaf, {free_text: searchValue})
        setIsSearch(false)
        setIsSearchReady(true)
        setSearchValue("")
    }

    if (!column || !column.show) {
        return (
            <td style={{borderTop: "1px solid #ECECEF"}}/>
        )
    }

    return (
        <td style={{borderTop: "1px solid #ECECEF"}}>
            <div className={styles.option__wrapper}>
                {isMultiply && (
                    <div onClick={() => checkedLine(columnIndex, column)}
                         className={`${styles.checkbox} ${selectedMembers.some(member => member.id === column.id) && styles.active}`} />
                )}
                <div className={`${styles.option} ${openColumns && styles.open}`}>
                    <div style={{width: "100%"}}>
                        <div className={styles.option__block} onClick={() => clickColumns(openColumns)}>
                            <div className={styles.option__block_icon}>
                                {column.icon && (
                                    <GetIcon backendUrl={generateIconUrl(column.icon, "icon")}/>
                                )}
                            </div>
                            <span  dangerouslySetInnerHTML={{__html: column.formattedName}}/>
                        </div>
                        {isSearch && (
                            <div style={{display: "flex", gap: 5, alignItems: "center"}}>
                                <SearchLineSearch onSearch={onSearch} getSearchValue={setSearchValue}
                                                  showAcceptBtn={false}/>
                            </div>
                        )}
                    </div>
                    <div style={{display: "flex", alignItems: "center", gap: 5}}>
                        {!isSearchReady && (
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 version="1.0" width="24" height="24"
                                 viewBox="0 0 128 128"
                                 className={styles.option__loader}>
                                <path fill="#323238"
                                      d="M64.4 16a49 49 0 0 0-50 48 51 51 0 0 0 50 52.2 53 53 0 0 0 54-52c-.7-48-45-55.7-45-55.7s45.3 3.8 49 55.6c.8 32-24.8 59.5-58 60.2-33 .8-61.4-25.7-62-60C1.3 29.8 28.8.6 64.3 0c0 0 8.5 0 8.7 8.4 0 8-8.6 7.6-8.6 7.6z">
                                    <animateTransform attributeName="transform" type="rotate" from="0 64 64" to="360 64 64"
                                                      dur="1800ms" repeatCount="indefinite">
                                    </animateTransform>
                                </path>
                            </svg>
                        )}
                        {isSearchReady && searchValue && (
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                                 style={{cursor: "pointer"}} onClick={() => onSearch(searchValue)}>
                                <path
                                    d="M3.1579 10.75L7.17761 16.1628C7.8996 17.135 9.31312 17.2476 10.1799 16.402L20.8421 6"
                                    stroke="#323238" strokeWidth="2" strokeLinecap="round"/>
                            </svg>
                        )}
                        {!column.is_leaf && (
                            <div className={`${styles.option__icon} ${styles.search} ${!isSearch && styles.hide}`}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     onClick={() => setIsSearch(!isSearch)}
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M10.875 18.75C15.2242 18.75 18.75 15.2242 18.75 10.875C18.75 6.52576 15.2242 3 10.875 3C6.52576 3 3 6.52576 3 10.875C3 15.2242 6.52576 18.75 10.875 18.75Z"
                                        stroke="#7B7B80" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M16.4453 16.4453L21.0016 21.0016" stroke="#7B7B80" strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                </svg>
                            </div>
                        )}
                        {!column.is_leaf && (
                            <div className={styles.option__icon} onClick={() => clickColumns(openColumns)}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18 9L12 15L6 9" stroke="#323238" strokeWidth="2" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                </svg>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </td>
    )
}
