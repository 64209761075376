export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const REQUEST_VERSION = "/v1"

//AUTH
const AUTH = `${REQUEST_VERSION}/auth`;
export const API_REGISTRATION_URL = `${AUTH}/register`;
export const API_LOGIN_URL = `${AUTH}/login`;
export const API_FORGOT_PASSWORD_URL = `${AUTH}/reset-password`;
export const API_CONFIRM_EMAIL_URL = `${AUTH}/confirm-email`;
export const API_USER_ME_URL = `${AUTH}/user/me`;

const MEDICAL = `/medical`

//MEDICAL DATA
const MEDICAL_DATA = `${MEDICAL}/card`
export const API_MEDICAL_DATA_LIST = `${MEDICAL_DATA}`
export const API_MEDICAL_GET_NEW_CARD = `${MEDICAL_DATA}/new`
export const API_MEDICAL_DATA_CREATE_URL = `${MEDICAL_DATA}`
export const API_MEDICAL_DATA_DELETE_URL = `${MEDICAL_DATA}`
export const API_MEDICAL_HISTOPATHOLOGY_REPORT = `/report/histopathology`

//CATALOG_CONTENT
const CATALOG_CONTENT = `${MEDICAL}/catalog/content`
export const API_CATALOG_CONTENT_LIST = `${CATALOG_CONTENT}`

//CATALO
const CATALOG = `/catalog`
export const API_UPLOAD_CATALOG_URL = `${CATALOG}/upload`
export const API_MAKE_GET_CATALOG_URL = catalogId => `${CATALOG}/${catalogId}`
export const API_MAKE_DELETE_CATALOG_URL = catalogId => `${CATALOG}/${catalogId}`
export const API_MAKE_CSV_CATALOG_URL = catalogId => `${CATALOG}/download/csv/${catalogId}`
export const API_MAKE_EDIT_CATALOG_URL = catalogId => `${CATALOG}/${catalogId}`

//CATALOG
const CATALOGS = `/catalogs`
export const API_LIST_CATALOG_URL = `${CATALOGS}`

//RECORD
const RECORD = `${MEDICAL}/record`
export const API_CREATE_RECORD_URL = `${RECORD}`
export const API_UPDATE_RECORD_URL = `${RECORD}`
export const API_DELETE_RECORD_URL = `${RECORD}`
export const API_GET_CURRENT_TEMPLATE_RECORD_URL = `${RECORD}`
export const API_GET_TEMPLATE_RECORD_URL = `${RECORD}/template`

//RESULT
const RESULT = `${MEDICAL}/result`
export const API_CREATE_RESULT_URL = `${RESULT}`
export const API_UPDATE_RESULT_URL = `${RESULT}`
export const API_DELETE_RESULT_URL = `${RESULT}`

//BLOB
const BLOB = `/blob`
export const BLOB_UPLOAD_FILE_URL = `${BLOB}`
export const BLOB_GET_FILE_URL = `${BLOB}`

const FRONTEND = `/frontend`
const SETTINGS = `/settings`

//TRANSLATION
const TRANSLATION = `${SETTINGS}/translation`
export const API_LIST_TRANSLATION_URL = `${TRANSLATION}`
export const API_CREATE_TRANSLATION_URL = `${TRANSLATION}`


//WIDGET
const WIDGETS = `${SETTINGS}/widget`
export const API_LIST_WIDGETS_URL = `${WIDGETS}`
export const API_UPDATE_WIDGETS_URL = `${WIDGETS}`
export const API_CREATE_WIDGETS_URL = `${WIDGETS}`
export const API_DELETE_WIDGETS_URL = `${WIDGETS}`

const NOTIFICATIONS = `/notifications`
export const API_GET_NOTIFICATIONS_URL = `${NOTIFICATIONS}`

export const API_GET_WIDGETS_NEW_URL = `${FRONTEND}/settings`

//SETTINGS
export const API_GET_SETTINGS = `${FRONTEND}/settings`


//FOLDER
const FOLDER = `${SETTINGS}/folder`
export const API_LIST_FOLDERS_URL = `${FOLDER}`
export const API_UPDATE_FOLDERS_URL = `${FOLDER}`
export const API_CREATE_FOLDERS_URL = `${FOLDER}`
export const API_DELETE_FOLDERS_URL = `${FOLDER}`

//SHARED
const SHARING = `/sharing`
export const API_SHARING_CREATE_TOKEN_URL = `${SHARING}/token`
export const API_SHARING_GET_TOKEN_URL = `${SHARING}/token`


//WIDGET
const ENTERTAINMENT = `/entertainment`
export const API_LEADER_BOARD_ENTERTAINMENT_URL = `${ENTERTAINMENT}/galaxy/statistic`
export const API_UPLOAD_ARTEFACT_ENTERTAINMENT_URL = `${ENTERTAINMENT}/galaxy/artefact`



const SECURITY = `/security`
export const API_LIST_SECURITY_URL = `${SECURITY}/instance`
export const API_CREATE_SECURITY_URL = `${SECURITY}/instance`
export const API_DELETE_SECURITY_URL = `${SECURITY}/instance`
export const API_INCARNATION_SECURITY_URL = `${SECURITY}/incarnation`
export const API_ACTION_SECURITY_URL = `${SECURITY}/action`

