export default class ParseMedicalCardApi {

    //GET MEMBERS
    static getResultFromFolderByMemberId(folder, memberId) {
        if(!folder){
            console.error("Folder not found")
            return null
        }

        let result = null;

        folder.record.some( record => {
            const desiredResult = ParseMedicalCardApi.getResultFromRecordByMemberId(record, memberId);

            if(desiredResult){
                result = desiredResult;
                return true;
            }

            return false
        })

        return result
    }

    static getResultFromRecordByMemberId(record, memberId) {
        if(!record){
            console.error("Record not found")
            return null
        }


        let resultValue = null;

        record.result.some( result => {
            const desiredMember = result.member.find( member => member.id === memberId);

            if(desiredMember){
                resultValue = result;
                return true;
            }

            return false
        })

        return resultValue
    }

    static getResultsFromRecordByMemberId(record, memberId) {
        if(!record){
            console.error("Record not found")
            return null
        }

        return record.result.filter( result => result.member.some( member => member.id === memberId))
    }

    static getMemberFromMedicalCardByDimensionId(card, dimensionId) {
        if(!card){
            console.error("Card is not found")
            return null
        }

        const desiredMember = card.member.find( member => member.dimension.id === dimensionId);

        if(!desiredMember){
            return null
        }

        desiredMember.data = {
            key: desiredMember.dimension.name,
            value: desiredMember.name,
        }

        return desiredMember
    }

    static getResultFromCard(){

    }

    //FORMAT FOLDER
    static formatFoldersWithChildren( folders ){
        let parentFolders = folders.filter( folder => !folder.parent)
        let childrenFolders = folders.filter( folder => folder.parent)

        childrenFolders.forEach(( childFolder) => {
            const parentIndexFolder = parentFolders.findIndex( parentFolder => parentFolder.id === childFolder.parent.id)

            if(parentIndexFolder === -1){
                return;
            }

            parentFolders[parentIndexFolder].childrenFolder = [...(parentFolders[parentIndexFolder].childrenFolder || []), childFolder]
        }, {})


        return parentFolders;
    }

    static getRecordFromCard(){

    }
}
