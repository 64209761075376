/**
 * external libs
 */
import React, {useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import {v4 as uuidv4} from 'uuid';
/**
 * components
 */
import Widget from "../../../Widget";
/**
 * components
 */
import WidgetNameEnum from "../../../../enums/api/WidgetName";
import IncarnationIdEnum from "../../../../enums/api/Incarnation";
import SelectMembers from "../../../NewSelectMembers";
import {InputNumber} from "antd";
import FieldItem from "../../../Form/components/FieldItem";
import matchTheSameMembers from "../../../../utils/matchTheSameMembers";
import ActionsEnum from "../../../../enums/api/Actions";

export default function CreateNewCardMember({
                                                folderId,
                                                catalogId,
                                                getMembers,
                                                createNewCard = () => {},
                                                initialOpen = true,
                                                hideUnusedRowsMode = true,
                                            }) {
    const [members, setMembers] = useState([]);
    const [isFinished, setIsFinished] = useState(false);

    const getMember = (members, includeMember, originalIncludeMember) => {
        const currentGlobalMembers = matchTheSameMembers(originalIncludeMember.global_members)
        const currentLocalMembers = matchTheSameMembers(originalIncludeMember.local_members)
        const allMembers = [...members, ...currentGlobalMembers, ...currentLocalMembers]
        const allFiltredMembers = [...new Set(allMembers.map(({id}) => id))].map(currentId => {
            return allMembers.find(({id}) => id === currentId)
        })

        setMembers(allFiltredMembers.map(({id, name, dimension, columnOptions}) => ({
            id: id,
            name: name,
            incarnation: columnOptions?.dimension.incarnation[0].id || dimension.incarnation[0].id
        })))
    }
    useEffect(() => {
        if(isFinished && members.length){
            createNewCard(members)
        }
    }, [isFinished, members])

    useEffect(() => {
        getMembers(members)
    }, [members])

    return (
        <div>
            <SelectMembers
                incarnationId={IncarnationIdEnum.id.cardHolder}
                actionId={ActionsEnum.create}
                catalogId={catalogId}
                folderId={folderId}
                initialOpen={initialOpen}
                getMembers={getMember}
                getIsFinish={setIsFinished}
                hideUnusedRowsMode={hideUnusedRowsMode}
            />
        </div>
    )

}
