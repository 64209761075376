/**
 *service
 */
import axios from 'axios'
/**
 *service
 */
import StorageService from "./storage.service";
/**
 * const
 */
import {
    API_MEDICAL_DATA_CREATE_URL,
    API_MEDICAL_DATA_DELETE_URL,
    API_MEDICAL_DATA_LIST, API_MEDICAL_GET_NEW_CARD,
    API_MEDICAL_HISTOPATHOLOGY_REPORT
} from "../constants/api.constant";
/**
 * models
 */
import MedicalCardModel from "../model/medicalCard/medicalCard.model";
/**
 * utils
 */
import {QueryString} from "../utils/Querystring";
import AuthService from "./auth.service";

export default class MedicalCardService {
    static async list( params = {} ) {
        return await axios.get(API_MEDICAL_DATA_LIST, {
            params,
            paramsSerializer: params => {
                return QueryString.stringifyToBackand(params);
            },
        })
            .then(medicalCards => medicalCards.map( medicalCard => new MedicalCardModel(medicalCard)))
            .catch(e => Promise.reject(e))
    }

    static async getNew( params = {} ) {
        return await axios.get(API_MEDICAL_GET_NEW_CARD, {
            params,
            paramsSerializer: params => {
                return QueryString.stringify(params);
            },
        })
            .then(medicalCards => medicalCards.map( medicalCard => new MedicalCardModel(medicalCard)))
            .catch(e => Promise.reject(e))
    }

    static async create( data = {} ) {
        return await axios.post(API_MEDICAL_DATA_CREATE_URL,  data)
            .catch(e => Promise.reject(e))
    }

    static async delete( medicalCardId ) {
        return await axios.delete(API_MEDICAL_DATA_DELETE_URL,{params: {id: medicalCardId}})
            .catch(e => Promise.reject(e))
    }

    static async getHistopathologyReport(params = {}) {
        try {
            const response = await axios.get(API_MEDICAL_HISTOPATHOLOGY_REPORT, {
                params,
                paramsSerializer: params => QueryString.stringify(params),
            });
            if (response && response.patient_card) {
                return {
                    patientCard: response.patient_card ? new MedicalCardModel(response.patient_card) : null,
                    facilityCard: response.facility_card ? new MedicalCardModel(response.facility_card) : null,
                    doctorCard: response.doctor_card ? new MedicalCardModel(response.doctor_card) : null,
                    title: response.title || null,
                    language: response.language || null,
                };
            } else {
                return null;
            }
        } catch (e) {
            return Promise.reject(e);
        }
    }
}
