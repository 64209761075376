/**
 * external libs
 */
import React, {createContext, useState, useCallback, useEffect, useMemo} from 'react';
import {useMsal, useIsAuthenticated} from "@azure/msal-react";
/**
 *  components
 */
import AuthService from '../../services/auth.service';
import MedicalCardService from "../../services/medicalCard.service";
import FolderIdEnum from "../../enums/api/FolderId";
import StorageService from "../../services/storage.service";
import WidgetService from "../../services/widget.service";
import SettingsService from "../../services/settings.service";
import {MEDICAL_CARDS_MAKE_PAGE_URI} from "../../constants/uri.constant";
import {useHistory, useLocation} from "react-router-dom";
import {useContext} from "react";
import {AlertContext} from "./alert.context";
import {GlobalFolderContext} from "./globalFolder.context";
import Auth from "../../utils/Auth";
import AuthMethodEnum from "../../enums/AuthMethod";

export const AuthContext = createContext({});


export function AuthContextProvider({children}) {
    const {instance, inProgress} = useMsal();
    const history = useHistory();
    const isAuthenticated = useIsAuthenticated();
    const {setAlertError} = useContext(AlertContext);
    const {setGlobalFolder} = useContext(GlobalFolderContext);
    const [user, setUser] = useState(null);
    const [medicalCard, setMedicalCard] = useState(null);
    const [isReady, setIsReady] = useState(false);

    const getUserInfo = useCallback(async () => {
        try {
            const {roles} = await SettingsService.get()
            setUser({roles: roles})
        } catch (e) {
            console.error(e)
        }
    }, []);

    const getMedicalCard = useCallback(async (params) => {
        try {
            const [medicalCard] = await MedicalCardService.list({
                "folder_id": FolderIdEnum.myCards,
                "record.folders": FolderIdEnum.contacts,
            })


            if (!medicalCard) {
                const [medicalCard] = await MedicalCardService.getNew({
                    folder_id: FolderIdEnum.myCards,
                    // "folder.incarnation_ids": IncarnationIdEnum.id.record,
                    "record.folders": FolderIdEnum.contacts
                })

                setMedicalCard(medicalCard)
                setGlobalFolder(medicalCard.folder[0]);
                return history.push({
                    pathname: MEDICAL_CARDS_MAKE_PAGE_URI(FolderIdEnum.myCards, "new", FolderIdEnum.contacts),
                    state: {
                        parentFolderId: FolderIdEnum.myCards,
                    },
                })
            }

            setGlobalFolder(medicalCard.folder[0]);
            setMedicalCard(medicalCard);

            const location = window.location.pathname + window.location.search
            if (StorageService.redirectUrl && (StorageService.redirectUrl !== location)) {
                const [pathname, search] = StorageService.redirectUrl.split("?")

                history.push({
                    pathname: pathname,
                    search: search ? `?${search}` : ""
                })

                return StorageService.removeRedirectUrl()
            }else{
                return StorageService.removeRedirectUrl()
            }

            return medicalCard
        } catch (e) {
            setMedicalCard(null)
        } finally {
            setIsReady(true);
        }
    }, []);

    useEffect(() => {
        if (Auth.getAuthMethod() === AuthMethodEnum.azure && (inProgress === "none" && !user)) {
            if (!isAuthenticated) {
                return setIsReady(true)
            }

            getUserInfo()
                .then(async () => {
                    instance.setActiveAccount(instance.getAllAccounts()[0])
                    await getMedicalCard({})
                })
                .catch(() => setAlertError("We have some problem with auth module"))
        }
    }, [inProgress]);

    useEffect(() => {
        if (Auth.getAuthMethod() === AuthMethodEnum.corIdentity && !user) {
            getUserInfo()
                .then(async () => {
                    await getMedicalCard({})
                })
                .catch(() => setAlertError("We have some problem with auth module"))
        }
    }, []);

    return isReady ? <AuthContext.Provider
        value={{
            user,
            setUser,
            medicalCard,
            setMedicalCard,
            getMedicalCard
        }}>{children}</AuthContext.Provider> : null;
}
