/**
 * external libs
 */
import React, {useEffect, useMemo, useState} from 'react'
/**
 * components
 */
import EditableHTMLWidget from "./components/Editable";
/**
 * enums
 */
import ResultKeysEnum from "../../../../enums/api/ResultKeys";
import getWidget from "../../../../utils/getWidget";
import {ValidateResult} from "../../../../utils/validateResult";
import {markdownToDraft} from "markdown-draft-js";
import {ContentState, convertFromRaw, EditorState} from "draft-js";
import { stateToHTML } from 'draft-js-export-html';

export default function WidgetHTML({ isFocused, result, widget, getError = () => {}, field, editable, onEdit, showDescription, }){
    const [validationErrorMessage, setValidationErrorMessage] = useState('')

    const widgetProps = useMemo(() => {
        if(!widget){
            return null;
        }

        return field ? widget.result.schema.properties[field] : widget.result.schema;
    }, [field, widget])
    const resultValue = useMemo(() => {
        if(!result?.measures){
            return null
        }

        const newResultValue = field ? result.measures?.[field] : result.measures;

        return widgetProps.type.includes(typeof newResultValue) ? newResultValue : null
    }, [widgetProps, result?.measures])

    const validateResultHandler = (resultData) => {
        const {valid, message} = ValidateResult.validMeasures(resultData, widget, field)

        setValidationErrorMessage(!valid && message ? message : "")
        getError(!valid && message)
    }

    if(!result){
        return null;
    }

    if(editable){
        return (
            <EditableHTMLWidget widget={widgetProps}
                                  result={result}
                                  field={field}
                                  isFocused={isFocused}
                                  onEdit={onEdit}
                                  resultValue={resultValue}
                                  validationErrorMessage={validationErrorMessage}
                                  onBlur={validateResultHandler}/>
        )
    }


    if (showDescription && widgetProps.description && resultValue){
        return (
            <div className={"resetOff"}>
                {widgetProps.description && (
                    <span style={{ fontWeight: 600 }}>
                        {widgetProps.description}:
                    </span>
                )}
                <span dangerouslySetInnerHTML={{ __html: resultValue || "" }} />
            </div>
        )
    }

    return (
        <div className={"resetOff"}>
            <span dangerouslySetInnerHTML={{ __html: resultValue || "" }} />
        </div>
    )
}
