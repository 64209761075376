/**
 * external libs
 */
import React, {useEffect, useRef, useState} from 'react'
import {Input} from "antd";
/**
 * styles
 */
import styles from './index.module.scss'
/**
 * enums
 */
import ResultKeysEnum from "../../../../../../enums/api/ResultKeys";
import FieldItem from "../../../../../Form/components/FieldItem";

function validURL(str) {
    const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
}

export default function EditableLinkWidget({isFocused = true, widget, validateResultHandler, onEdit, field, onBlur, result}){
    const [resultData, setResultData] = useState(result)
    const inputRef = useRef(null)

    const onBlurHandler = () => {
        if(onEdit){
            onEdit(resultData)
        }

        if(onBlur){
            onBlur(resultData)
        }
    }

    const onChangeHandler = (e) => {
        const value = e.target.value.trim() || null;


        if((value.length < widget.minLength) || (value.length > widget.maxLength)){
            return;
        }

        if(widget.pattern){
            const reg = new RegExp(widget.pattern)

            if(!reg.test(value)){
                return;
            }
        }

        if(!validURL(value)){
            return;
        }


        setResultData({
            ...resultData,
            is_modified: true,
            measures: {
                ...resultData.measures,
                [field]: e.target.value.trim(),
            }
        })
        onEdit({
            ...resultData,
            is_modified: true,
            measures: {
                ...resultData.measures,
                [field]: e.target.value.trim(),
            }
        })
    }

    useEffect(() => {
        setResultData(result)
    }, [result])

    useEffect(() => {
        if(isFocused){
            inputRef.current.focus()
        }
    }, [])


    return (
        <FieldItem label={widget.description || ""}
                   requiredColor={resultData.measures?.[field] ? "#1084FF" : "red"}
                   required={!widget.type.includes("null")}  stylesWrapper={{marginBottom: 10}} error={validateResultHandler}>
            <div className={styles.wrapper} style={{maxWidth: 500, width: 500}}>
                <Input rootClassName={styles.wrapper__input}
                       defaultValue={resultData.measures?.[field] || ""}
                       ref={inputRef}
                       onChange={onChangeHandler}
                       onBlur={onBlurHandler}
                />
            </div>
        </FieldItem>
    )
}
