import MemberModel from './../member.model';
import ResultWidgetModel from './resultWidget.model';
import ColumnModel from './../catalog/content/column.model';
import RowModel from './../catalog/content/row.model';


export default class WidgetModel {
  constructor(data = {}) {
    this.id = data.id || '';
    this.members = data.members ? data.members.map(group => group.map(member => new MemberModel(member))) : [];
    // this.result = data.result ? new ResultWidgetModel(data.result) : null;
    this.result = data.result ? data.result : {
      schema: {
        properties: [],
      }
    };
    this.warning = data.warning || '';
    this.rows = data.rows ? data.rows.map(row => new RowModel(row)) : [];
    this.columns = data.columns ? data.columns.map(column => new ColumnModel(column)) : [];
  }
}
