/**
 * external libs
 */
import React, {useContext, useEffect, useMemo, useState} from 'react'
import {NavLink, useHistory, useLocation} from "react-router-dom";
/**
 * styles
 */
import ChildrenItem from '../ChildrenItem'
/**
 * styles
 */
import styles from './index.module.scss'
import GetIcon from "../../../GetIcon";
import AddMedCard from "../AddMedCard";
import FolderIdEnum from '../../../../enums/api/FolderId'
import {NavigationRefreshContext} from "../../../../pages/context/navigationRefresh.context";
import {Tooltip} from "antd";

const WrapperComponent = ({
                              children, link, click = () => {
    }, item, itemId, disableFolders, activeFolder, openSideBar, setOpenChildren, showTooltip
                          }) => {
    const active = useMemo(() => itemId && (activeFolder === itemId), [activeFolder, itemId]);
    const disabled = useMemo(() => disableFolders && !active, [active, disableFolders]);
    const {setGeneralNavigationRefresh} = useContext(NavigationRefreshContext)
    const history = useHistory()
    const pathNameLink = link?.split("#")[0];
    const isCurrentUrl = window.location.pathname === pathNameLink
    const isActiveUrl = window.location.pathname.startsWith(pathNameLink)

    const TooltipWrapper = ({ children }) => {
        if(showTooltip && !openSideBar){
            return (
                <Tooltip placement="top" title={item.title}>
                    {children}
                </Tooltip>
            )
        }


        return (
            <>
                {children}
            </>
        )
    }

    if (link) {
        return (
            <TooltipWrapper>
                <div className={`${styles.item} ${(active || isActiveUrl) && styles.active} ${!openSideBar && styles.close} ${disabled && styles.disabled}`}
                     onClick={() => {
                         if(!isCurrentUrl){
                             click(item)
                             history.push(link)
                             return;
                         }

                         history.replace( {
                             search: ""
                         })
                         setGeneralNavigationRefresh(new Date().getTime())
                     }}>
                    {children}
                </div>
            </TooltipWrapper>
        )
    }

    return (
        <TooltipWrapper>
            <div className={`${styles.item} ${!openSideBar && styles.close} ${disabled && styles.disabled}`}
                 style={{opacity: disableFolders ? .5 : 1}}
                 onClick={() => {
                     if (!disableFolders) {
                         setOpenChildren(open => !open)
                         click(item)
                     }
                 }}>
                {children}
            </div>
        </TooltipWrapper>
    )
}

export default function SideBarItem({item, index, activeFolder, openSideBar = true, showTooltip = true, disableFolders, firstActive}) {
    const location = useLocation()
    const [active, setActive] = useState((firstActive && index === 0) || !location.pathname.indexOf(item.link))
    const [openChildren, setOpenChildren] = useState(false)

    useEffect(() => {
        setActive((firstActive && index === 0) || !location.pathname.indexOf(item.link))
    }, [location.pathname])

    const generateLink = (link, hash) => {
        let generatedLink = link;

        if (!generatedLink) {
            return null
        }

        if (hash) {
            generatedLink += `#${hash}`
        }

        return generatedLink
    }

    return (
        <React.Fragment>
            <div className={styles.hover}>
                <WrapperComponent to={item.link} click={item.onClick} item={item} itemId={item.folderId}
                                  activeFolder={activeFolder} disableFolders={disableFolders}
                                  link={generateLink(item.link, item.hash)} openSideBar={openSideBar}
                                  active={active} setOpenChildren={setOpenChildren} showTooltip={showTooltip}>
                    <div className={styles.item__flex}>
                        <div className={styles.item__icon}>
                            <GetIcon {...item.iconProps}/>
                        </div>
                        <p className={styles.item__title}>{item.title}</p>
                    </div>
                </WrapperComponent>
                <div className={`${styles.item__flex} ${styles.item__actions}`}>
                    {item.addCard && (item.folderId !== FolderIdEnum.myCards) &&
                        <AddMedCard folder={item.folder} folderId={item.folderId}/>}
                    {item.children && (
                        <svg className={`${styles.item__action} ${styles.arrow} ${openChildren && styles.open}`} width="24" height="24"
                             viewBox="0 0 24 24" fill="none"
                             onClick={() => setOpenChildren(open => !open)}
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 9L12 15L6 9" stroke="#323238" strokeWidth="2" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                        </svg>
                    )}
                    {!item.children && item.action && item.action()}
                </div>
                {!openSideBar && item.addCard && (item.folderId !== FolderIdEnum.myCards) && (
                    <div className={styles.item__mobileActions}>
                        <AddMedCard folder={item.folder} folderId={item.folderId}/>
                    </div>
                )}
            </div>
            {openChildren && item.children &&
                <ChildrenItem items={item.children} activeFolder={activeFolder} disableFolders={disableFolders}
                              openSideBar={openSideBar} setOpenChildren={setOpenChildren}/>}
        </React.Fragment>
    )
}
