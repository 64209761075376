/**
 * external libs
 */
import React, {useState} from 'react'
import {Tooltip} from "antd";
/**
 * components
 */
import EditableLastMemberWidget from "./components/Editable";
import {useMemo} from "react";
import getWidget from "../../../../utils/getWidget";
import GetIcon from "../../../GetIcon";
import generateIconUrl from "../../../../utils/generateIconUrl";
import {ValidateResult} from "../../../../utils/validateResult";

export default function WidgetLastMember({ result, isFocused, hideMembers = [], changeMembersHandler, widget, catalogId, getError = () => {}, field, editable, onEdit, showDescription, folderId }){
    const [validationErrorMessage, setValidationErrorMessage] = useState('')

    const lastMemberDimensionId = result.member[result.member.length - 1]?.dimension.id

    const lastMembers = useMemo(() => {
        return result.member.filter(member => member.dimension.id === lastMemberDimensionId)
    }, [lastMemberDimensionId, result.member])

    const firstMembers = useMemo(() => {
        return result.member.filter(member => member.dimension.id !== lastMemberDimensionId)
    }, [lastMemberDimensionId, result.member])

    const validateResultHandler = (resultData) => {
        const {valid, message} = ValidateResult.validMeasures(resultData, widget, field)
        // const {valid, message} = ValidateResult.validateLastMember(lastMembers)

        setValidationErrorMessage(!valid && message ? message : "")
        getError(!valid && message)
    }

    if(!result || !result.member.length){
        return null
    }

    if(editable){
        return (
            <EditableLastMemberWidget folderId={folderId}
                                      widget={widget.result.schema.properties[field]}
                                      field={field}
                                      result={result}
                                      isFocused={isFocused}
                                      onEdit={onEdit}
                                      catalogId={catalogId}
                                      validationErrorMessage={validationErrorMessage}
                                      validateResultHandler={validateResultHandler}
                                      lastMembers={lastMembers}
                                      changeMembersHandler={changeMembersHandler}
                                      firstMembers={firstMembers}
                                      onBlur={() => {}}/>
        )
    }

    if(showDescription && widget.result.schema.properties[field].description){
        return (
            <div>
                <div>
                    {widget.result.schema.properties[field].description}:
                </div>
                <div style={{display: "flex", alignItems: "center", gap: 5}}>
                    {lastMembers.map( member => {
                        if(hideMembers.includes(member.id)){
                            return null
                        }

                        return (
                            <Tooltip placement="top" title={member.dimension.name}>
                                <div className={"itemLastMember"} style={{background: "#dbe9ef", padding: "3px 5px",borderRadius: 6, display: "flex", alignItems: "center"}}>
                                    {member.icon && (
                                        <div style={{width: 18, minWidth: 18, marginRight: 18}}>
                                            <GetIcon backendUrl={generateIconUrl(member.icon, "icon")}/>
                                        </div>
                                    )}
                                    {member.name}
                                </div>
                            </Tooltip>
                        )
                    })}
                </div>
            </div>
        )
    }

    return (
        <div style={{display: "flex", alignItems: "center", flexWrap: "wrap", gap: 5}}>
            {lastMembers.map( member => {
                if(hideMembers.includes(member.id)){
                    return null
                }


                return (
                    (
                        <Tooltip placement="top" title={member.dimension.name}>
                            <div className={"itemLastMember"} style={{background: "#dbe9ef", padding: "3px 7px",borderRadius: 6, display: "flex", alignItems: "center"}}>
                                {member.icon && (
                                    <div style={{width: 16, minWidth: 16, height: 16, marginRight: 5}}>
                                        <GetIcon backendUrl={generateIconUrl(member.icon, "icon")}/>
                                    </div>
                                )}
                                {member.name}
                            </div>
                        </Tooltip>
                    )
                )
            })}
        </div>
    )
}
