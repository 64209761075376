import DimensionModel from "./dimension.model";

export default class MemberModel {
    constructor(data = {}) {
        this.id = data.id;
        this.ordinal = data.ordinal
        this.name = data.name;
        this.ordinal = data.ordinal;
        this.icon = data.icon;
        this.color = data.color;
        this.dimension = data.dimension ? new DimensionModel(data.dimension) : null;
    }
}
